import React, { useState, useContext, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import openSocket from 'socket.io-client'
// import FilterPanel from '../../custom/components/organisms/FilterPanel'
import FilterPanel from '@/product/components/species/dynamic/common/FilterPanel/Filter'
import GridView from '../../custom/components/molecules/ProductCard/GridView'
import ProductContext from '../../custom/context/product/productContext'
import AuctionContext from '../../product/context/auction/auctionContext'
import SpecialEvent from '../../custom/components/molecules/ProductCard/SpecialEvent'
import CommonContext from '../../custom/context/common/commonContext'
import ProductView from '../../custom/components/organisms/ProductViewComponent'
import './Home.css'
import clsx from 'clsx'
import Slider from 'react-slick'
import { makeStyles } from '@material-ui/core/styles'
import AlertContext from '../../product/context/alert/alertContext'
import AuthContext from '../../product/context/auth/authContext'
import Button from '@material-ui/core/Button'
import { Drawer, useTheme } from '@material-ui/core'
import { useFormik } from 'formik'
import { messageHandler } from '@/product/common/socketHandler'
import { useCustomMediaQuery } from '../../product/common/components'
import CustomDialog from '../../custom/components/organisms/CustomDialog'
import Banner from '../../custom/components/molecules/Banner'
import PrimaryButton from '../../product/components/atoms/PrimaryButton'
import SecondaryButton from '../../product/components/atoms/SecondaryButton'
import SpecialEventSkeleton from '../../custom/components/molecules/ProductCard/ProductCardSkeletons/SpecialEventSkeleton'
import HomeSkeleton from './HomeSkeleton'
import DynamicContext from '@/product/context/dynamic/dynamicContext'
import { initialSearchFunction } from '@/product/components/species/dynamic/common/FieldFunction'
import { handleRedirectInternal } from '@/custom/common/components'
import { useHistory } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react'
import { FreeMode, Navigation, Pagination } from 'swiper/modules'
import Loaders from '@/product/components/molecules/Loaders'
import { useTranslation } from 'react-i18next'
import settingContext from '@/product/context/seller/setting/settingContext'
import { useMediaQuery } from 'react-responsive'
import ReadMore from '@/product/components/atoms/ReadMore'
const useStyles = makeStyles({
    fullList: {
        width: 'auto',
    },
})

const Home = () => {
    const productContext = useContext(ProductContext)
    const auctionContext = useContext(AuctionContext)
    const authContext = useContext(AuthContext)
    const commonContext = useContext(CommonContext)
    const alertContext = useContext(AlertContext)
    const dynamicContext = useContext(DynamicContext)
    const { searchTable, table_data } = useContext(settingContext)

    const history = useHistory()
    const { getAllDynamicArray, dynamicValue } = dynamicContext
    const { setAlert } = alertContext
    const { user, isAuthenticated, saveSearch } = authContext
    const { search_allauctions, getAllAuctionProducts } = auctionContext
    const { search_allproducts, getAllSearchProducts, responseStatus } = productContext
    const { setSearchValue, currentLocation, allHomeBanner, alldata_all_dropdown } = commonContext

    const [previousSlideIndex, setPreviousSlideIndex] = useState(0)
    const [currentSlideIndex, setCurrentSlideIndex] = useState(0)
    const [nextSlideIndex, setNextSlideIndex] = useState(0)
    let [viewProduct, setViewProduct] = useState([])
    let [auctions, setAuctions] = useState([])
    const [loadingProduct, setLoadingProduct] = useState(false)
    const [loadingAuction, setLoadingAuction] = useState(false)
    const [dynamicArray, setDynamicArray] = useState([])
    const [arrayValue, setArrayValue] = useState([])
    const [initialValues, setInitialValues] = useState({})
    const [initialEmptyValues, setInitialEmptyValues] = useState({})
    const [update, setUpdate] = useState({})
    const [show, setShow] = useState(false)
    const [initialUpdate, setInitialUpdate] = useState(false)
    const [reload, setReload] = useState(false)
    const [reviews, setReviews] = useState([])

    const classes = useStyles()
    const isMobile = useMediaQuery({ maxWidth: 767 })

    const [state, setState] = useState({
        right: false,
        bottom: false,
        data: {},
    })

    const formik = useFormik({
        initialValues: {
            searchbar: '',
        },
    })

    const formikSearch = useFormik({
        initialValues: {
            limit: 12,
            page: 1,
            orderby: 'p.id, desc',
            order: '',
            auctionView: 'Grid',
            filters: initialValues,
        },
        enableReinitialize: true,
    })

    const clearSearchFilter = () => {
        formikSearch.values = {
            ...formikSearch.values,
            limit: 12,
            page: 1,
            orderby: 'p.id, desc',
            order: '',
            filters: initialEmptyValues,
        }

        formikSearch.handleReset()
        setUpdate(formikSearch)
        console.log(formikSearch.values, 'filterrr')
        setShow(true)
    }

    useEffect(() => {
        if (table_data.records.length) setReviews(table_data.records)
    }, [table_data])

    useEffect(() => {
        setInitialValues({})
        setInitialEmptyValues({})
        setInitialUpdate(false)

        if (arrayValue.length) {
            let mapValueArray = arrayValue

            let show = initialSearchFunction(mapValueArray)
            // console.log('filter=====>', show)
            setInitialValues({ ...show, ...search?.filters })
            setInitialEmptyValues({ ...show })
            setTimeout(() => {
                if (Object.keys(show).length) {
                    setInitialUpdate(true)
                    setReload(!reload)
                }
            }, 1000)
        }
        // else {
        //     let custom = {}
        //     setInitialValues(custom)
        //     setInitialEmptyValues(custom)
        //     setTimeout(() => {
        //         if (Object.keys(custom).length) {
        //             setInitialUpdate(true)
        //             setReload(!reload)
        //         }
        //     }, 1000)
        // }
    }, [arrayValue])
    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && formik.values.searchbar !== '') {
            setSearchValue({
                filters: {
                    searchbar: {
                        value: formik.values.searchbar,
                    },
                },
            })
        }
    }

    const handleCatgClick = (val) => {
        setSearchValue({
            filters: {
                category: {
                    value: [val.toString()],
                },
            },
        })
        handleRedirectInternal(history, 'search')
    }

    const [search, setSearch] = useState({
        page: 1,
        limit: 10,
        order: '',
        orderby: 'p.date_closed,asc',
        filters: {
            action: {
                value: 'open',
                type: 'in',
                field: 'p.market_status',
            },
            searchterm: {
                value: '',
                type: 'like',
                field: 'p.title',
            },
            fromdate: {
                value: '',
                type: 'dategreaterequal',
                field: 'p.date_closed',
            },
            todate: {
                value: '',
                type: 'datesmallerequal',
                field: 'p.date_closed',
            },
            featured: {
                value: 1,
                type: 'in',
                field: 'p.featured',
            },
        },
    })

    const [searchAuction, setSearchAuction] = useState({
        limit: 10,
        page: 1,
        order: '',
        orderby: 'ac.date_closed,desc',
        filters: {
            market_status: {
                value: 'open',
                type: 'in',
                field: 'ac.market_status',
            },
            p_market_status: {
                value: 'open',
                type: 'in',
                field: 'p.market_status',
            },
            // currentLocation: {
            // state: currentLocation.state,
            // city: currentLocation.city,
            // },
        },
    })

    useEffect(() => {
        getAllDynamicArray(
            {
                page: 1,
                limit: 20,
                order: '',
                orderby: '',
                table: 'dynamic_fields',
                filters: {
                    fields: {
                        value: '1',
                        type: 'in',
                        field: 'df.view_page',
                    },
                    search: {
                        value: '1',
                        type: 'in',
                        field: 'df.search',
                    },
                    active: {
                        value: '1',
                        type: 'in',
                        field: 'df.active',
                    },
                },
            },
            'searchDynamic',
        )
        searchTable(
            {
                page: 1,
                limit: 5,
                order: '',
                orderby: 'ur.id,desc',
                table: 'user_review',
                filters: {
                    fields: {
                        value: 1,
                        type: 'in',
                        field: 'ur.active',
                    },
                },
                tableJoins: [
                    {
                        baseTable: 'users',
                        // customTable: 'users',
                        joinType: 'inner',
                        baseJoinField: 'id',
                        connectJoinField: 'user_id',
                    },
                ],
            },
            'getReview',
        )
    }, [])

    useEffect(() => {
        if (dynamicValue.from === 'searchDynamic') {
            if (dynamicValue.records.length) {
                // console.log('dynamicval========>', dynamicValue.records)
                setDynamicArray(dynamicValue.records)
            }
        }
    }, [dynamicValue])

    useEffect(() => {
        if (dynamicArray.length) {
            let arrayAdded = dynamicArray
            let sortedArray = arrayAdded.sort((a, b) => (a.filter_order > b.filter_order ? 1 : -1))
            // console.log(sortedArray, 'arrayass=====>')
            setArrayValue(sortedArray)
        }
    }, [dynamicArray])

    useEffect(() => {
        // console.log('data===========>', formikSearch.values)
        getAllSearchProducts(formikSearch.values)
    }, [formikSearch.values])

    useEffect(() => {
        setLoadingProduct(true)
        setLoadingAuction(true)
        getAllSearchProducts(search)
        getAllAuctionProducts(searchAuction)
    }, [])

    const locationInitialLoad = useRef(true)
    useEffect(() => {
        if (locationInitialLoad.current == true) {
            locationInitialLoad.current = false
        } else {
            let searchFilter = { ...search }
            // searchFilter.filters.currentLocation = {
            // state: currentLocation.state,
            // city: currentLocation.city,
            // }
            setSearch(searchFilter)
            getAllSearchProducts(searchFilter)

            let searchAuctionFilter = { ...searchAuction }
            // searchAuctionFilter.filters.currentLocation = {
            // state: currentLocation.state,
            // city: currentLocation.city,
            // }
            setSearchAuction(searchAuctionFilter)
            getAllAuctionProducts(searchAuctionFilter)
        }
    }, [currentLocation])

    const onHandlePageAuction = (event, value) => {
        setSearchAuction({ ...searchAuction, page: value })
    }

    const productInitialLoad = useRef(true)
    const auctionInitialLoad = useRef(true)
    useEffect(() => {
        //console.log('search_allproducts', search_allproducts)
        if (productInitialLoad.current) {
            productInitialLoad.current = false
        } else {
            setLoadingProduct(false)
            setViewProduct(search_allproducts.records.length ? search_allproducts.records : [])
        }
    }, [search_allproducts])

    useEffect(() => {
        //console.log('search_allauctions', search_allauctions)
        if (auctionInitialLoad.current) {
            auctionInitialLoad.current = false
        } else {
            setLoadingAuction(false)
            setAuctions(search_allauctions.records.length ? search_allauctions.records : [])
        }
    }, [search_allauctions])

    useEffect(() => {
        setViewProduct([])
        return () => {
            setViewProduct([])
        }
    }, [])
    const { t } = useTranslation()
    const viewProductRef = useRef(viewProduct)
    const userRef = useRef(user)
    useEffect(() => {
        viewProductRef.current = viewProduct
        userRef.current = user
    })

    const handler = (message, type) => {
        messageHandler(
            message,
            viewProductRef.current,
            userRef.current,
            setAlert,
            setViewProduct,
            type,
        )
    }
    useEffect(() => {
        const socket = openSocket(`${process.env.REACT_APP_DOMAIN}/`, { transports: ['websocket'] })
        socket.on('realclosedupdates', (data) => {
            handler(data, 'realclosedupdates')
        })
        socket.on('bidAddtime', (data) => {
            handler(data, 'bidAddtime')
        })
        return () => {
            socket.off('realclosedupdates', (data) => {
                handler(data, 'realclosedupdates')
            })
            socket.off('bidAddtime', (data) => {
                handler(data, 'bidAddtime')
            })
            socket.disconnect()
        }
    }, [])

    useEffect(() => {
        if (state.right) {
            const index = viewProduct.findIndex((s) => s.id == parseInt(state.data.id, 10))
            if (index !== -1) {
                setState({ ...state, data: viewProduct[index] })
            }
        }
    }, [viewProduct])

    const toggleDrawer = (anchor, open, data) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return
        }
        if (open) {
            setState({ ...state, [anchor]: open, data: data })
        } else {
            setState({ ...state, [anchor]: open, data: {} })
        }
    }

    const NextArrow = (props) => {
        const { className, style, onClick } = props
        return <div className={className} onClick={onClick} />
    }

    const PrevArrow = (props) => {
        const { className, style, onClick } = props
        return <div className={className} onClick={onClick} />
    }

    const reviewSettings = {
        dots: false,
        infinite: false,
        speed: 1000,
        autoplay: false,
        autoplaySpeed: 2000,
        slidesToShow: 3,
        slidesToScroll: 1,
        adaptiveHeight: true,
        // variableWidth: true,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 500,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    }

    const [swiper, setSwiper] = useState(null)
    const theme = useTheme()

    useEffect(() => {
        // Reinitialize Swiper when the direction changes
        if (swiper) {
            swiper.destroy()
            setSwiper(null)
        }
    }, [theme?.direction])

    const handleSwiper = (swiper) => {
        setSwiper(swiper)
    }

    return (
        <div className="home customContainer">
            <div className="homeBanner">
                <Swiper
                    dir={theme?.direction}
                    key={theme?.direction}
                    modules={[Navigation, Pagination]}
                    onSwiper={handleSwiper}
                    // pagination={{
                    //     clickable: true,
                    // }}
                    navigation={false}
                    // freeMode={true}
                    className="bannerSwiper"
                >
                    {allHomeBanner && allHomeBanner.length
                        ? allHomeBanner.map((data, index) =>
                              data.active === 1 ? (
                                  <SwiperSlide key={`banner_${index}`}>
                                      <div className="hbContent">
                                          <h1 style={{ color: data?.color || '#fff' }}>
                                              {data.title}
                                          </h1>
                                          <p style={{ color: data?.color || '#fff' }}>
                                              {data.description}
                                          </p>
                                          {console.log(global.images_url, 'checkData')}
                                          {data?.link ? (
                                              <Button
                                                  onClick={() => window.open(data.link, '_blank')}
                                                  className="hero-btn"
                                                  style={{
                                                      border: `1px solid ${data?.color}`,
                                                      color: data?.color,
                                                  }}
                                              >
                                                  {t('view_details')}
                                              </Button>
                                          ) : null}
                                      </div>
                                  </SwiperSlide>
                              ) : null,
                          )
                        : null}
                </Swiper>

                {isMobile ? (
                    <div className="hbImages mobile">
                        <div className="hiItemMob" onClick={() => handleCatgClick(312)}>
                            {alldata_all_dropdown[
                                alldata_all_dropdown.findIndex(
                                    (ele) =>
                                        ele.description == 'Cars' && ele.variable == 'category',
                                )
                            ]?.avatar ? (
                                <img
                                    src={`${global.images_url}${
                                        alldata_all_dropdown[
                                            alldata_all_dropdown.findIndex(
                                                // (ele) => ele.id == 312
                                                (ele) =>
                                                    ele.description == 'Cars' &&
                                                    ele.variable == 'category',
                                            )
                                        ]?.avatar
                                    }`}
                                    alt="Homepage Banner"
                                    loading="lazy"
                                />
                            ) : null}

                            <span>{t('Cars')}</span>
                        </div>
                        <div className="hiItemMob" onClick={() => handleCatgClick(317)}>
                            {alldata_all_dropdown[
                                alldata_all_dropdown.findIndex(
                                    (ele) =>
                                        ele.description == 'Clothes' && ele.variable == 'category',
                                )
                            ]?.avatar ? (
                                <img
                                    src={`${global.images_url}${
                                        alldata_all_dropdown[
                                            alldata_all_dropdown.findIndex(
                                                // (ele) => ele.id == 317
                                                (ele) =>
                                                    ele.description == 'Clothes' &&
                                                    ele.variable == 'category',
                                            )
                                        ]?.avatar
                                    }`}
                                    alt="Homepage Banner"
                                    loading="lazy"
                                />
                            ) : null}
                            <span>{t('Clothes')}</span>
                        </div>
                        <div className="hiItemMob" onClick={() => handleCatgClick(319)}>
                            {alldata_all_dropdown[
                                alldata_all_dropdown.findIndex(
                                    (ele) =>
                                        ele.description === 'Electronics' &&
                                        ele.variable == 'category',
                                )
                            ]?.avatar ? (
                                <img
                                    src={`${global.images_url}${
                                        alldata_all_dropdown[
                                            alldata_all_dropdown.findIndex(
                                                // (ele) => ele.id == 319
                                                (ele) =>
                                                    ele.description === 'Electronics' &&
                                                    ele.variable == 'category',
                                            )
                                        ]?.avatar
                                    }`}
                                    alt="Homepage Banner"
                                    loading="lazy"
                                />
                            ) : null}
                            <span>{t('Electronics')}</span>
                        </div>
                        <div className="hiItemMob" onClick={() => handleCatgClick(314)}>
                            {alldata_all_dropdown[
                                alldata_all_dropdown.findIndex(
                                    (ele) =>
                                        ele.description == 'Games' && ele.variable == 'category',
                                )
                            ]?.avatar ? (
                                <img
                                    src={`${global.images_url}${
                                        alldata_all_dropdown[
                                            alldata_all_dropdown.findIndex(
                                                // (ele) => ele.id == 314
                                                (ele) =>
                                                    ele.description == 'Games' &&
                                                    ele.variable == 'category',
                                            )
                                        ]?.avatar
                                    }`}
                                    alt="Homepage Banner"
                                    loading="lazy"
                                />
                            ) : null}
                            <span>{t('Games')}</span>
                        </div>
                        <div className="hiItemMob" onClick={() => handleCatgClick(318)}>
                            {alldata_all_dropdown[
                                alldata_all_dropdown.findIndex(
                                    (ele) =>
                                        ele.description == 'Home' && ele.variable == 'category',
                                )
                            ]?.avatar ? (
                                <img
                                    src={`${global.images_url}${
                                        alldata_all_dropdown[
                                            alldata_all_dropdown.findIndex(
                                                // (ele) => ele.id == 318
                                                (ele) =>
                                                    ele.description == 'Home' &&
                                                    ele.variable == 'category',
                                            )
                                        ]?.avatar
                                    }`}
                                    alt="Homepage Banner"
                                    loading="lazy"
                                />
                            ) : null}
                            <span>{t('home')}</span>
                        </div>
                        <div className="hiItemMob" onClick={() => handleCatgClick(315)}>
                            {alldata_all_dropdown[
                                alldata_all_dropdown.findIndex(
                                    (ele) =>
                                        ele.description == 'Perfumes' && ele.variable == 'category',
                                )
                            ]?.avatar ? (
                                <img
                                    src={`${global.images_url}${
                                        alldata_all_dropdown[
                                            alldata_all_dropdown.findIndex(
                                                // (ele) => ele.id == 315
                                                (ele) =>
                                                    ele.description == 'Perfumes' &&
                                                    ele.variable == 'category',
                                            )
                                        ]?.avatar
                                    }`}
                                    alt="Homepage Banner"
                                    loading="lazy"
                                />
                            ) : null}
                            <span>{t('Perfumes')}</span>
                        </div>
                        <div className="hiItemMob" onClick={() => handleCatgClick(322)}>
                            {alldata_all_dropdown[
                                alldata_all_dropdown.findIndex(
                                    (ele) =>
                                        ele.description == 'Printers' && ele.variable == 'category',
                                )
                            ]?.avatar ? (
                                <img
                                    src={`${global.images_url}${
                                        alldata_all_dropdown[
                                            alldata_all_dropdown.findIndex(
                                                // (ele) => ele.id == 322
                                                (ele) =>
                                                    ele.description == 'Printers' &&
                                                    ele.variable == 'category',
                                            )
                                        ]?.avatar
                                    }`}
                                    alt="Homepage Banner"
                                    loading="lazy"
                                />
                            ) : null}
                            <span>{t('Printers')}</span>
                        </div>
                        <div className="hiItemMob" onClick={() => handleCatgClick(320)}>
                            {alldata_all_dropdown[
                                alldata_all_dropdown.findIndex(
                                    (ele) =>
                                        ele.description == 'Shoes' && ele.variable == 'category',
                                )
                            ]?.avatar ? (
                                <img
                                    src={`${global.images_url}${
                                        alldata_all_dropdown[
                                            alldata_all_dropdown.findIndex(
                                                // (ele) => ele.id == 320
                                                (ele) =>
                                                    ele.description == 'Shoes' &&
                                                    ele.variable == 'category',
                                            )
                                        ]?.avatar
                                    }`}
                                    alt="Homepage Banner"
                                    loading="lazy"
                                />
                            ) : null}
                            <span>{t('Shoes')}</span>
                        </div>
                        <div className="hiItemMob" onClick={() => handleCatgClick(316)}>
                            {alldata_all_dropdown[
                                alldata_all_dropdown.findIndex(
                                    (ele) =>
                                        ele.description == 'Watches' && ele.variable == 'category',
                                )
                            ]?.avatar ? (
                                <img
                                    src={`${global.images_url}${
                                        alldata_all_dropdown[
                                            alldata_all_dropdown.findIndex(
                                                // (ele) => ele.id == 316
                                                (ele) =>
                                                    ele.description == 'Watches' &&
                                                    ele.variable == 'category',
                                            )
                                        ]?.avatar
                                    }`}
                                    alt="Homepage Banner"
                                    loading="lazy"
                                />
                            ) : null}

                            <span>{t('Watches')}</span>
                        </div>
                        <div className="hiItemMob" onClick={() => handleCatgClick(321)}>
                            {alldata_all_dropdown[
                                alldata_all_dropdown.findIndex(
                                    (ele) =>
                                        ele.description == 'General Merchandise' &&
                                        ele.variable == 'category',
                                )
                            ]?.avatar ? (
                                <img
                                    src={`${global.images_url}${
                                        alldata_all_dropdown[
                                            alldata_all_dropdown.findIndex(
                                                // (ele) => ele.id == 321
                                                (ele) =>
                                                    ele.description == 'General Merchandise' &&
                                                    ele.variable == 'category',
                                            )
                                        ]?.avatar
                                    }`}
                                    alt="Homepage Banner"
                                    loading="lazy"
                                />
                            ) : null}
                            <span>{t('General Merchandise')}</span>
                        </div>
                    </div>
                ) : (
                    <div className="hbImages">
                        <div className="hbiOdd">
                            <div className="hiItem" onClick={() => handleCatgClick(312)}>
                                {alldata_all_dropdown[
                                    alldata_all_dropdown.findIndex(
                                        (ele) =>
                                            ele.description == 'Cars' && ele.variable == 'category',
                                    )
                                ]?.avatar ? (
                                    <img
                                        src={`${global.images_url}${
                                            alldata_all_dropdown[
                                                alldata_all_dropdown.findIndex(
                                                    // (ele) => ele.id == 312,
                                                    (ele) =>
                                                        ele.description == 'Cars' &&
                                                        ele.variable == 'category',
                                                )
                                            ]?.avatar
                                        }`}
                                        alt="Homepage Banner"
                                        loading="lazy"
                                    />
                                ) : null}

                                <span>{t('Cars')}</span>
                            </div>
                            <div className="hiItem" onClick={() => handleCatgClick(317)}>
                                {alldata_all_dropdown[
                                    alldata_all_dropdown.findIndex(
                                        (ele) =>
                                            ele.description == 'Clothes' &&
                                            ele.variable == 'category',
                                    )
                                ]?.avatar ? (
                                    <img
                                        src={`${global.images_url}${
                                            alldata_all_dropdown[
                                                alldata_all_dropdown.findIndex(
                                                    // (ele) => ele.id == 317,
                                                    (ele) =>
                                                        ele.description == 'Clothes' &&
                                                        ele.variable == 'category',
                                                )
                                            ]?.avatar
                                        }`}
                                        alt="Homepage Banner"
                                        loading="lazy"
                                    />
                                ) : null}
                                <span>{t('Clothes')}</span>
                            </div>
                            <div className="hiItem" onClick={() => handleCatgClick(319)}>
                                {alldata_all_dropdown[
                                    alldata_all_dropdown.findIndex(
                                        (ele) =>
                                            ele.description == 'Electronics' &&
                                            ele.variable == 'category',
                                    )
                                ]?.avatar ? (
                                    <img
                                        src={`${global.images_url}${
                                            alldata_all_dropdown[
                                                alldata_all_dropdown.findIndex(
                                                    // (ele) => ele.id == 314,
                                                    (ele) =>
                                                        ele.description == 'Electronics' &&
                                                        ele.variable == 'category',
                                                )
                                            ]?.avatar
                                        }`}
                                        alt="Homepage Banner"
                                        loading="lazy"
                                    />
                                ) : null}
                                <span>{t('Electronics')}</span>
                            </div>
                        </div>
                        <div className="hbiEven">
                            <div className="hiItem" onClick={() => handleCatgClick(314)}>
                                {alldata_all_dropdown[
                                    alldata_all_dropdown.findIndex(
                                        (ele) =>
                                            ele.description == 'Games' &&
                                            ele.variable == 'category',
                                    )
                                ]?.avatar ? (
                                    <img
                                        src={`${global.images_url}${
                                            alldata_all_dropdown[
                                                alldata_all_dropdown.findIndex(
                                                    // (ele) => ele.id == 314,
                                                    (ele) =>
                                                        ele.description == 'Games' &&
                                                        ele.variable == 'category',
                                                )
                                            ]?.avatar
                                        }`}
                                        alt="Homepage Banner"
                                        loading="lazy"
                                    />
                                ) : null}
                                <span>{t('Games')}</span>
                            </div>
                            <div className="hiItem" onClick={() => handleCatgClick(318)}>
                                {alldata_all_dropdown[
                                    alldata_all_dropdown.findIndex(
                                        (ele) =>
                                            ele.description == 'Home' && ele.variable == 'category',
                                    )
                                ]?.avatar ? (
                                    <img
                                        src={`${global.images_url}${
                                            alldata_all_dropdown[
                                                alldata_all_dropdown.findIndex(
                                                    // (ele) => ele.id == 318,
                                                    (ele) =>
                                                        ele.description == 'Home' &&
                                                        ele.variable == 'category',
                                                )
                                            ]?.avatar
                                        }`}
                                        alt="Homepage Banner"
                                        loading="lazy"
                                    />
                                ) : null}
                                <span>{t('Home')}</span>
                            </div>
                        </div>
                        <div className="hbiOdd">
                            <div className="hiItem" onClick={() => handleCatgClick(315)}>
                                {alldata_all_dropdown[
                                    alldata_all_dropdown.findIndex(
                                        (ele) =>
                                            ele.description == 'Perfumes' &&
                                            ele.variable == 'category',
                                    )
                                ]?.avatar ? (
                                    <img
                                        src={`${global.images_url}${
                                            alldata_all_dropdown[
                                                alldata_all_dropdown.findIndex(
                                                    // (ele) => ele.id == 315,
                                                    (ele) =>
                                                        ele.description == 'Perfumes' &&
                                                        ele.variable == 'category',
                                                )
                                            ]?.avatar
                                        }`}
                                        alt="Homepage Banner"
                                        loading="lazy"
                                    />
                                ) : null}
                                <span>{t('Perfumes')}</span>
                            </div>
                            <div className="hiItem" onClick={() => handleCatgClick(322)}>
                                {alldata_all_dropdown[
                                    alldata_all_dropdown.findIndex(
                                        (ele) =>
                                            ele.description == 'Printers' &&
                                            ele.variable == 'category',
                                    )
                                ]?.avatar ? (
                                    <img
                                        src={`${global.images_url}${
                                            alldata_all_dropdown[
                                                alldata_all_dropdown.findIndex(
                                                    // (ele) => ele.id == 322,
                                                    (ele) =>
                                                        ele.description == 'Printers' &&
                                                        ele.variable == 'category',
                                                )
                                            ]?.avatar
                                        }`}
                                        alt="Homepage Banner"
                                        loading="lazy"
                                    />
                                ) : null}
                                <span>{t('Printers')}</span>
                            </div>
                            <div className="hiItem" onClick={() => handleCatgClick(320)}>
                                {alldata_all_dropdown[
                                    alldata_all_dropdown.findIndex(
                                        (ele) =>
                                            ele.description == 'Shoes' &&
                                            ele.variable == 'category',
                                    )
                                ]?.avatar ? (
                                    <img
                                        src={`${global.images_url}${
                                            alldata_all_dropdown[
                                                alldata_all_dropdown.findIndex(
                                                    // (ele) => ele.id == 320,
                                                    (ele) =>
                                                        ele.description == 'Shoes' &&
                                                        ele.variable == 'category',
                                                )
                                            ]?.avatar
                                        }`}
                                        alt="Homepage Banner"
                                        loading="lazy"
                                    />
                                ) : null}
                                <span>{t('Shoes')}</span>
                            </div>
                        </div>
                        <div className="hbiEven">
                            <div className="hiItem" onClick={() => handleCatgClick(316)}>
                                {alldata_all_dropdown[
                                    alldata_all_dropdown.findIndex(
                                        (ele) =>
                                            ele.description == 'Watches' &&
                                            ele.variable == 'category',
                                    )
                                ]?.avatar ? (
                                    <img
                                        src={`${global.images_url}${
                                            alldata_all_dropdown[
                                                alldata_all_dropdown.findIndex(
                                                    // (ele) => ele.id == 316,
                                                    (ele) =>
                                                        ele.description == 'Watches' &&
                                                        ele.variable == 'category',
                                                )
                                            ]?.avatar
                                        }`}
                                        alt="Homepage Banner"
                                        loading="lazy"
                                    />
                                ) : null}

                                <span>{t('Watches')}</span>
                            </div>
                            <div className="hiItem" onClick={() => handleCatgClick(321)}>
                                {alldata_all_dropdown[
                                    alldata_all_dropdown.findIndex(
                                        (ele) =>
                                            ele.description == 'General Merchandise' &&
                                            ele.variable == 'category',
                                    )
                                ]?.avatar ? (
                                    <img
                                        src={`${global.images_url}${
                                            alldata_all_dropdown[
                                                alldata_all_dropdown.findIndex(
                                                    // (ele) => ele.id == 321,
                                                    (ele) =>
                                                        ele.description == 'General Merchandise' &&
                                                        ele.variable == 'category',
                                                )
                                            ]?.avatar
                                        }`}
                                        alt="Homepage Banner"
                                        loading="lazy"
                                    />
                                ) : null}
                                <span>{t('General Merchandise')}</span>
                            </div>
                        </div>
                    </div>
                )}
            </div>

            <div className="howItWorksCnt">
                <h2>{t('What to Expect')}?</h2>
                <div className="howItWorksInner">
                    <div>
                        <img src="/assets/svg/bid.svg" alt="icon" />
                        <h3>{t('Bid & Win')}</h3>
                        <p>{t('Bid on thousands of name-brand items listed daily.')}</p>
                    </div>
                    <div>
                        <img src="/assets/svg/calendar.svg" alt="icon" />
                        <h3>{t('Schedule & Pay')}</h3>
                        <p>
                            {t('After you win, schedule to pickup your items within seven days.')}
                        </p>
                    </div>
                    <div>
                        <img src="/assets/svg/car.svg" alt="icon" />
                        <h3>{t('Curbside Pick Up')}</h3>
                        <p>
                            {t(
                                'Head down to our facility and we’ll bring your items right to your vehicle.',
                            )}
                        </p>
                    </div>
                    <div>
                        <img src="/assets/svg/return.svg" alt="icon" />
                        <h3>{t('7 Day Returns')}</h3>
                        <p>
                            {t(
                                'If you’re unhappy with an item, bring it back for a refund within 7 days.',
                            )}
                        </p>
                    </div>
                </div>
            </div>

            <div className="trendingItems">
                <div className="homeTitle d-flex justify-content-between align-items-center">
                    <h2>{t('Featured Products')}</h2>
                    {viewProduct.length ? (
                        <SecondaryButton
                            id="view_lot"
                            label={t('View all lots')}
                            btnSize="small"
                            onClick={() => handleRedirectInternal(history, 'search')}
                        ></SecondaryButton>
                    ) : null}
                </div>
                {loadingProduct ? (
                    <Loaders name="product_grid_view" isLoading={loadingProduct} loop={4} />
                ) : viewProduct.length ? (
                    <Swiper
                        modules={[FreeMode, Navigation]}
                        freeMode={true}
                        className="trendingSwiper"
                        slidesPerView={4}
                        spaceBetween={10}
                        breakpoints={{
                            300: {
                                slidesPerView: 1,
                            },
                            768: {
                                slidesPerView: 2,
                            },
                            1200: {
                                slidesPerView: 3,
                            },
                            1366: {
                                slidesPerView: 4,
                            },
                        }}
                    >
                        {viewProduct.map((data, index) => (
                            <SwiperSlide>
                                <GridView data={data} favId={`searchProd_${index}`} />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                ) : (
                    <div className="np-pro-box"> {t('No Records Found!')} </div>
                )}
            </div>

            <div className="activeAuctions">
                <div className="homeTitle d-flex justify-content-between align-items-center">
                    <h2>
                        {t('active')} {t('auctions')}
                    </h2>
                    {auctions?.length ? (
                        <SecondaryButton
                            id="view_auction"
                            label={t('View all auctions')}
                            btnSize="small"
                            onClick={() => handleRedirectInternal(history, 'auction')}
                        ></SecondaryButton>
                    ) : null}
                </div>
                {loadingAuction ? (
                    <Loaders name="product_grid_view" isLoading={loadingAuction} loop={4} />
                ) : auctions.length ? (
                    <Swiper
                        modules={[Navigation]}
                        className="activeSwiper"
                        slidesPerView={4}
                        spaceBetween={10}
                        breakpoints={{
                            300: {
                                slidesPerView: 1,
                            },
                            768: {
                                slidesPerView: 2,
                            },
                            1200: {
                                slidesPerView: 3,
                            },
                            1366: {
                                slidesPerView: 4,
                            },
                        }}
                    >
                        {auctions.map((data, index) => (
                            <SwiperSlide>
                                <SpecialEvent data={data} />{' '}
                            </SwiperSlide>
                        ))}
                    </Swiper>
                ) : (
                    <div className="np-pro-box"> {t('No Records Found!')} </div>
                )}
            </div>

            <div className="activeAuctions">
                <div className="homeTitle d-flex justify-content-between align-items-center">
                    <h2>
                        {t('Video')} {t('auctions')}
                    </h2>
                    {/* {auctions?.length ? <Link to="/auction">{t('View all auctions')}</Link> : null} */}
                </div>
                {loadingAuction ? (
                    <Loaders name="product_grid_view" isLoading={loadingAuction} loop={4} />
                ) : auctions.length ? (
                    <Swiper
                        modules={[Navigation]}
                        className="activeSwiper"
                        slidesPerView={4}
                        spaceBetween={10}
                        breakpoints={{
                            300: {
                                slidesPerView: 1,
                            },
                            768: {
                                slidesPerView: 2,
                            },
                            1200: {
                                slidesPerView: 3,
                            },
                            1366: {
                                slidesPerView: 4,
                            },
                        }}
                    >
                        {auctions.map((data, index) => (
                            <SwiperSlide>
                                <SpecialEvent data={data} videoAuction={true} />{' '}
                            </SwiperSlide>
                        ))}
                    </Swiper>
                ) : (
                    <div className="np-pro-box"> {t('No Records Found!')} </div>
                )}
            </div>

            <div className="reviewCnt">
                <h2>{t('reviews')}</h2>
                <Swiper
                    modules={[Pagination]}
                    autoHeight={true}
                    pagination={{
                        clickable: true,
                    }}
                    freeMode={true}
                    className="reviewSwiper"
                    slidesPerView={3}
                    spaceBetween={10}
                    breakpoints={{
                        300: {
                            slidesPerView: 1,
                        },
                        768: {
                            slidesPerView: 2,
                        },
                        1200: {
                            slidesPerView: 3,
                        },
                    }}
                >
                    {reviews.map((review) => (
                        <SwiperSlide className="reviewCard">
                            <ReadMore
                                data={review.description}
                                showInModal={true}
                                limit={110}
                                customLabel={true}
                                readMoreLabel={t('Read more')}
                                readLessLabel={t('Read less')}
                                title={t('See Full Details')}
                            />
                            <div className="reviewUser">
                                {/* <img src="/assets/images/user.png" alt="user image" /> */}
                                <span className="userInitials">
                                    {review?.users_first_name?.charAt(0)}
                                    {review?.users_last_name?.charAt(0)}{' '}
                                </span>
                                <div>
                                    <h5>
                                        {review.users_first_name} {review.users_last_name}
                                    </h5>
                                    <div className="reviewRating">
                                        {Array.from({ length: 5 }, (_, index) => {
                                            if (index + 1 <= review.rating)
                                                return <span className="material-icons">star</span>
                                            if (
                                                index + 1 - review.rating > 0 &&
                                                index + 1 - review.rating < 1
                                            )
                                                return (
                                                    <span className="material-icons">
                                                        star_half
                                                    </span>
                                                )
                                            else {
                                                if (index == 0)
                                                    return (
                                                        <span className="material-icons">star</span>
                                                    )
                                                else
                                                    return (
                                                        <span className="material-icons">
                                                            star_outline
                                                        </span>
                                                    )
                                            }
                                        })}
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </div>
    )
}
export default Home
