import React, { useContext, useEffect, useState } from 'react'
import { currencyFormat, handleRedirectInternal } from '@/custom/common/components'
import PrimaryButton from '@/product/components/atoms/PrimaryButton'
import { useFormik } from 'formik'
import { useHistory } from 'react-router-dom'
import authContext from '@/product/context/auth/authContext'
import TertiaryButton from '@/custom/components/atoms/TertiaryButton'
import CustomDialog from '@/custom/components/organisms/Dialog'
import { Button } from '@material-ui/core'
import * as Yup from 'yup'
import BuynowContext from '@/product/context/buynow/buynowContext'
import { useTranslation } from 'react-i18next'
import { mapData } from '@/product/common/components'
// import SEO from '@/utils/SEO'
import AlertContext from '@/product/context/alert/alertContext'
import SecondaryButton from '../../atoms/SecondaryButton'

const Buynow = (props) => {
    const { isAuthenticated, user } = useContext(authContext)
    const { setAlert } = useContext(AlertContext)
    const { addToCart, responseStatus, clearResponse } = useContext(BuynowContext)
    const [product, setProduct] = useState()
    const [disabled, setDisabled] = useState(false)
    const { t } = useTranslation()

    const history = useHistory()

    const [toggleDialog, setToggleDialog] = useState(false)

    const changeDialogStatus = () => {
        setToggleDialog(!toggleDialog)
    }

    useEffect(() => {
        setProduct(props.data)
    }, [props.data])

    useEffect(() => {
        if (product) {
            formik.setFieldValue('product_id', product.id)
            // formik.setFieldValue('qty', product.qty - product.sold)
        }
    }, [product])

    const validationArray = Yup.object({
        product_id: Yup.number().required(t('Product id required')),
        qty: Yup.number()
            .min(1, `${t('min_quantity')} `)
            .max(
                product ? product.qty - product.sold : 0,
                `${t('max_quantity')} ${product ? product.qty - product.sold : 0}`,
            )
            .required(t('enter_quantity')),
    })

    const formik = useFormik({
        initialValues: { product_id: 0, qty: 1 },
        validationSchema: validationArray,
        onSubmit: (values) => {
            if (toggleDialog) {
                let cart = []
                if (user.status === 'suspended' || user.status === 'deactivate') {
                    if (user.status === 'suspended') {
                        setAlert(
                            'Your account has been suspended, please contact support@minnbid.org',
                            'error',
                        )
                    } else {
                        setAlert(
                            'Your account has been deactivated, please contact support@minnbid.org',
                            'error',
                        )
                    }
                } else {
                    cart.push(values)
                    let obj = { cart }
                    obj.update_qty = true
                    addToCart(obj)
                    setDisabled(true)
                }
            } else {
                setToggleDialog(true)
            }
        },
    })

    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'addToCart') {
                setDisabled(false)
                if (responseStatus.status === 'success') {
                    setToggleDialog(false)

                    handleRedirectInternal(history, 'buynowcart')
                } else {
                    setAlert(responseStatus.message, responseStatus.status)
                    setToggleDialog(false)
                }
            }
            clearResponse()
        }
    }, [responseStatus])

    const buynowQuantity = [
        {
            label: t('Enter Quantity'),
            name: 'qty',
            type: 'number',
            placeholder: `${t('max_quantity')} ${product ? product.qty - product.sold : 0}`,
            class: '',
            size: 'small',
            autoFocus: false,
            formik: formik,
        },
    ]

    return (
        <>
            {/* <SEO title="Auction view" url={window.location.href} /> */}
            {product ? (
                <>
                    {product.market_status === 'open' ? (
                        <form onSubmit={formik.handleSubmit}>
                            <div
                                className={`buyingCnt d-flex justify-content-between align-items-start ${props.className} `}
                            >
                                {props.editQuantity && Object.values(mapData(buynowQuantity))}
                                {isAuthenticated ? (
                                    <PrimaryButton
                                        id="buy_now"
                                        label={t('buynow')}
                                        type="submit"
                                        btnSize="small"
                                    />
                                ) : (
                                    <PrimaryButton
                                        id="login_to_buy"
                                        label={t('login_to_buy')}
                                        btnSize="small"
                                        onClick={() => {
                                            if (product.id > 0) {
                                                sessionStorage.setItem('productID', product.id)
                                            }
                                            handleRedirectInternal(history, 'login')
                                        }}
                                    />
                                )}
                            </div>
                        </form>
                    ) : null}
                </>
            ) : null}
            <CustomDialog
                title={t('confirm_buy_now')}
                open={toggleDialog}
                function={changeDialogStatus}
                className="confirmBidModal"
            >
                <h5>
                    {t('You are about to buy this product for')}{' '}
                    <span>
                        {currencyFormat(product?.bprice * formik.values.qty, t)} (
                        {currencyFormat(product?.bprice, t) + ' x ' + formik.values.qty + 'Q'})
                    </span>
                </h5>
                <h5>{t('please_confirm_if_you_want_to_continue_with_this_action')}?</h5>
                <div className="actionWrapper">
                    <Button id="buy_now_cancel" onClick={() => setToggleDialog(false)}>
                        {t('cancel')}
                    </Button>
                    <PrimaryButton
                        id="buy_now_submit"
                        onClick={formik.handleSubmit}
                        type="submit"
                        disabled={disabled}
                        label={t('submit')}
                    />
                </div>
            </CustomDialog>
        </>
    )
}

export default Buynow
