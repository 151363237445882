import React, { useState, useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { LOGO } from '../../../../Utils/UI'
import { Link, NavLink } from 'react-router-dom'
import {
    Badge,
    Button,
    Divider,
    ListItem,
    Menu,
    MenuItem,
    SwipeableDrawer,
} from '@material-ui/core'
import SecondaryButton from '../../../../product/components/atoms/SecondaryButton'
import AuthContext from '../../../../product/context/auth/authContext'
import CommonContext from '../../../context/common/commonContext'
import AlertContext from '../../../../product/context/alert/alertContext'
import { handleRedirectInternal } from '../../../common/components'
import FullScreenPopup from '../../organisms/FullScreenPopup'
import CustomDialog from '../../organisms/CustomDialog'
import ProductContext from '../../../../custom/context/product/productContext'
import { Popover } from '@material-ui/core'
import { useFormik } from 'formik'
import openSocket from 'socket.io-client'
import LanguageSwitch from '../LanguageSwitch'
import { useTranslation } from 'react-i18next'
const MainHeader = () => {
    const iOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent)
    const productContext = useContext(ProductContext)
    const authContext = useContext(AuthContext)
    const alertContext = useContext(AlertContext)
    const commonContext = useContext(CommonContext)
    const { addSavedSearch, getCartItems, cart_items, responseStatus } = productContext
    const { setSearchValue, allLocations, currentLocation, setCurrentLocation, searchValue } =
        commonContext
    const { allCategory } = commonContext
    const [modalOpen, setModalOpen] = useState(false)
    const [storeLocation, setStoreLocation] = useState(false)
    const [anchormenu, setanchormenu] = useState(null)
    const [anchorcart, setanchorcart] = useState(null)
    const locationArray = allLocations
        .filter((location) => {
            return location.active === 1
        })
        .map((location) => {
            return {
                id: location.id,
                city: location.city,
                state: location.state,
            }
        })

    const toggleFullScreenPopup = () => {
        setModalOpen(!modalOpen)
    }

    const toggleDirectFullScreenPopup = (value) => {
        setModalOpen(value)
    }
    const { t } = useTranslation()
    const {
        user,
        isAuthenticated,
        loadPendingCount,
        isAdmin,
        cartCount,
        logout,
        switchUser,
        login,
        responseStatus: responseStatusAuth,
        clearResponse: clearResponseAuth,
    } = authContext
    const [showCategory, setShowCategory] = useState(false)

    const { setAlert } = alertContext
    const [state, setState] = React.useState({
        right: false,
        top: false,
    })
    const toggleDrawer = (anchor, open) => (event) => {
        setState({ ...state, [anchor]: open })
    }

    const history = useHistory()

    const [anchorEl, setAnchorEl] = React.useState(null)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleCart = (event) => {
        setanchorcart(event.currentTarget)
    }
    const handleMenu = (event) => {
        setanchormenu(event.currentTarget)
    }

    const handleClose = (history, path) => {
        setAnchorEl(null)
        setanchormenu(null)
        setanchorcart(null)
        if (history && path && path !== 'backdropClick') {
            console.log('history', history, path)
            handleRedirectInternal(history, path)
        }
    }

    const onLogOut = () => {
        logout()
        handleClose()
        setAlert('Logged out successfully', 'success')
        handleRedirectInternal(history, '')
    }

    const viewStoreLocation = () => {
        setStoreLocation(!storeLocation)
    }

    const formik = useFormik({
        initialValues: {
            searchbar: '',
            category: '',
        },
    })

    useEffect(() => {
        const socket = openSocket(`${process.env.REACT_APP_DOMAIN}/`, { transports: ['websocket'] })
        // update cart count when a buyer wins item
        socket.on('realclosedupdates', (data) => {
            if (data.usr != '' && user?.id === parseInt(data.bpop_cbidder)) {
                getCartItems()
            }
        })
        return () => {
            socket.off('realclosedupdates', (data) => {
                if (data.usr != '' && user?.id === parseInt(data.bpop_cbidder)) {
                    getCartItems()
                }
            })
            socket.disconnect()
        }
    }, [])

    useEffect(() => {
        if (isAuthenticated) {
            let auctionFilter = {
                table: 'buynow',
                field: 'id',
                extraField: '',
                filters: {
                    buynow_autype: {
                        value: '',
                        type: 'in',
                        field: 'b.buynow_autype',
                    },
                    paid: {
                        value: '0',
                        type: 'in',
                        field: 'b.paid',
                    },
                    partial: {
                        value: '0',
                        type: 'in',
                        field: 'b.partial',
                    },
                    active: {
                        value: '2',
                        type: 'notin',
                        field: 'b.active',
                    },
                },
            }
            let buyNowFilter = {
                table: 'cart_temp',
                field: 'cart_id',
                extraField: '',
                filters: {
                    paid: {
                        value: '0',
                        type: 'in',
                        field: 'crt.paid',
                    },
                },
            }

            loadPendingCount(buyNowFilter, 'buynowCount')

            loadPendingCount(auctionFilter, 'auctionCount')
        }
    }, [isAuthenticated, history.location.pathname])

    const [count, setCount] = useState({
        auctionCount: 0,
        buynowCount: 0,
    })

    useEffect(() => {
        console.log(cartCount, 'yuuii')
        if (cartCount?.auctionCount) {
            setCount({ ...count, auctionCount: cartCount.auctionCount.totalRecords })
        }
        if (cartCount?.buynowCount) {
            setCount({ ...count, buynowCount: cartCount.buynowCount.totalRecords })
        }
    }, [cartCount])
    useEffect(() => {
        if (Object.keys(currentLocation).length > 0) {
            localStorage.setItem('currentLocationId', currentLocation.id)
            localStorage.setItem('currentCity', currentLocation.city)
            localStorage.setItem('currentState', currentLocation.state)
        }
    }, [currentLocation])

    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'payment') {
                getCartItems()
            }
        }
    }, [responseStatus])

    useEffect(() => {
        if (formik.values.category) {
            setSearchValue({
                filters: {
                    category: {
                        value: [formik.values.category],
                    },
                },
            })
        }
    }, [formik.values])

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            toggleDirectFullScreenPopup(false)
            setSearchValue({
                filters: {
                    searchbar: {
                        value: formik.values.searchbar,
                    },
                },
            })
            toggleDrawer('top', false)
        }
    }

    const handleSearch = () => {
        toggleDirectFullScreenPopup(false)
        setSearchValue({
            filters: {
                searchbar: {
                    value: formik.values.searchbar,
                },
            },
        })
        toggleDrawer('top', false)
    }

    const changeLocationHandler = (data) => {
        setCurrentLocation({
            id: data.id,
            city: data.city,
            state: data.state,
        })
    }

    const getOperationHours = (location) => {
        let date = new Date()
        const day = date.getDay()
        let i = allLocations.findIndex((loc) => {
            return loc.id == location.id
        })
        if (i !== -1) {
            if (day == 0) {
                return allLocations[i].sundayHours
            } else if (day == 6) {
                return allLocations[i].saturdayHours
            } else {
                return allLocations[i].weeklyHours
            }
        }
    }

    const addSavedSearchData = (search_text) => {
        addSavedSearch({
            search_text: search_text,
            // city: currentLocation.city,
            // state: currentLocation.state,
        })
    }

    useEffect(() => {
        if (
            searchValue &&
            searchValue.filters &&
            typeof searchValue.filters.searchbar !== 'undefined'
        ) {
            formik.setFieldValue('searchbar', searchValue.filters.searchbar.value)
        }
    }, [searchValue])

    const getSearchBarObject = (value) => {
        return {
            limit: 12,
            page: 1,
            orderby: 'p.id, desc',
            order: '',
            filters: {
                searchbar: {
                    value: value,
                    type: 'like',
                    field: 'p.title,p.desc_proc,p.id',
                },
            },
        }
    }
    const [searchText, setSearchText] = useState('')

    const handleSearchTextChange = (e) => {
        setSearchText(e.target.value)
    }

    const handleSearchButtonClick = () => {
        setSearchValue(getSearchBarObject(searchText))
        toggleDrawer('top', false)

        setState({ ...state, top: false })
        if (location.pathname != '/search') {
            handleRedirectInternal(history, 'search')
        }
    }

    console.log(searchValue, 'searchValue')

    const handleSearchKeyDown = (e) => {
        console.log(e, 'checkEvent')
        if (e?.key === 'Enter') {
            setSearchValue(getSearchBarObject(searchText))

            if (location.pathname != '/search') {
                handleRedirectInternal(history, 'search')
            }
        }
    }

    useEffect(() => {
        if (searchValue && searchValue.filters && searchValue.filters.searchbar) {
            setSearchText(searchValue.filters.searchbar.value)
        }
    }, [searchValue])

    return (
        <div className="mainHeader customContainer d-flex justify-content-between align-items-center">
            <div className="headLt d-flex justify-content-start align-items-center ">
                <Link to="/">
                    <img src={LOGO} alt="Naam Logo" />
                </Link>
                <div className="form-inline my-2 my-lg-0 headerSearchForm">
                    <input
                        className="form-control"
                        type="search"
                        placeholder={t('Search for products')}
                        aria-label="Search"
                        value={searchText}
                        onChange={handleSearchTextChange}
                        onKeyDown={handleSearchKeyDown}
                    />
                    <Button className="btn" onClick={handleSearchButtonClick}>
                        {t('Explore')}
                    </Button>
                </div>
            </div>
            <div>
                {/* DESKTOP NAVIGATION */}
                <div className="headRt deskNav d-flex justify-content-start align-items-center">
                    <ul className="d-flex justify-content-start align-items-center">
                        <li>
                            <NavLink
                                to="/"
                                activeClassName={history.location.pathname === '/' ? 'active' : ''}
                            >
                                {t('home')}
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/auction" activeClassName="active">
                                {t('live_auctions')}
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/search" activeClassName="active">
                                {t('auctions')}
                            </NavLink>
                        </li>
                        {/* <li>
                            <NavLink to="/contact" activeClassName="active">
                                {t('contact_us')}
                            </NavLink>
                        </li> */}
                        {/* <li className="ls" id="header_language">
                            <LanguageSwitch />
                        </li>{' '} */}
                        {isAuthenticated ? (
                            <>
                                <Button
                                    aria-controls="simple-cart"
                                    aria-haspopup="true"
                                    className="headerCart"
                                    onClick={handleCart}
                                    id="header_cart"
                                >
                                    <Badge
                                        badgeContent={count.buynowCount + count.auctionCount}
                                        color="primary"
                                    >
                                        <span className="material-icons">shopping_cart</span>
                                    </Badge>
                                </Button>
                                {/* <Popover
                                    anchorEl={anchorcart}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    id="simple-cart"
                                    keepMounted
                                    class={`headerDropMenu ${document.body.dir === 'rtl' && 'rtl'}`}
                                    open={Boolean(anchorcart)}
                                    onClose={handleClose}
                                >
                                    <ul
                                        className={`headerDropMenu ${
                                            document.body.dir === 'rtl' && 'rtl'
                                        }`}
                                    >
                                        <ListItem
                                            button
                                            onClick={() => handleClose(history, 'buynowcart')}
                                            id="header_buynowcart"
                                        >
                                            <Badge badgeContent={count.buynowCount} color="primary">
                                                <span className="material-icons">shopping_bag</span>
                                            </Badge>
                                            {t('buy_now_cart')}
                                        </ListItem>
                                        <ListItem
                                            button
                                            onClick={() => handleClose(history, 'cart')}
                                            id="header_auctioncart"
                                        >
                                            <Badge
                                                badgeContent={count.auctionCount}
                                                color="primary"
                                            >
                                                <span className="material-icons">gavel</span>
                                            </Badge>

                                            {t('auction_cart')}
                                        </ListItem>
                                    </ul>
                                </Popover> */}
                                <li>
                                    <Button
                                        aria-controls="simple-menu"
                                        aria-haspopup="true"
                                        onClick={handleClick}
                                        className="accDropdown"
                                    >
                                        {t('my_account')}
                                        {user && user.profile_image ? (
                                            <span className="profileImgCnt">
                                                <img
                                                    src={
                                                        global.site_url +
                                                        `/uploads/useravatar/${user.profile_image}`
                                                    }
                                                />
                                            </span>
                                        ) : (
                                            <span className="material-icons">account_circle</span>
                                        )}
                                    </Button>
                                    <Menu
                                        id="simple-menu"
                                        anchorEl={anchorEl}
                                        keepMounted
                                        className="headerMenu"
                                        open={Boolean(anchorEl)}
                                        onClose={handleClose}
                                    >
                                        <MenuItem onClick={() => handleClose(history, 'mypoints')}>
                                            {t('my_wallet')}
                                        </MenuItem>
                                        <MenuItem
                                            onClick={() =>
                                                handleClose(history, 'dashboard/auction')
                                            }
                                        >
                                            {t('my_auctions')}
                                        </MenuItem>
                                        <MenuItem onClick={() => handleClose(history, 'myaccount')}>
                                            {t('my_account')}
                                        </MenuItem>
                                        <MenuItem onClick={() => onLogOut()}>
                                            {t('logout')}
                                        </MenuItem>
                                    </Menu>
                                </li>
                            </>
                        ) : (
                            <li>
                                <SecondaryButton
                                    onClick={() => handleClose(history, 'login')}
                                    btnSize="small"
                                    label={t('Log In / Sign Up')}
                                />
                            </li>
                        )}
                    </ul>
                </div>
                {/* MOBILE / TAB NAVIGATION */}
                <Button className="respNavBtn">
                    <span className="material-icons" onClick={toggleDrawer('top', true)}>
                        search
                    </span>
                </Button>
                {isAuthenticated ? (
                    <>
                        {' '}
                        <Button
                            aria-controls="simple-cart"
                            aria-haspopup="true"
                            className="respNavBtn"
                            onClick={handleCart}
                            id="header_cart"
                        >
                            <Badge
                                badgeContent={count.buynowCount + count.auctionCount}
                                color="primary"
                            >
                                <span className="material-icons">shopping_cart</span>
                            </Badge>
                        </Button>
                        <Popover
                            anchorEl={anchorcart}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            id="simple-cart"
                            keepMounted
                            class={`headerDropMenu ${document.body.dir === 'rtl' && 'rtl'}`}
                            open={Boolean(anchorcart)}
                            onClose={handleClose}
                        >
                            <ul
                                className={`headerDropMenu ${document.body.dir === 'rtl' && 'rtl'}`}
                            >
                                <ListItem
                                    button
                                    onClick={() => handleClose(history, 'buynowcart')}
                                    id="header_buynowcart"
                                >
                                    <Badge badgeContent={count.buynowCount} color="primary">
                                        <span className="material-icons">shopping_bag</span>
                                    </Badge>
                                    {t('buy_now_cart')}
                                </ListItem>
                                <ListItem
                                    button
                                    onClick={() => handleClose(history, 'cart')}
                                    id="header_auctioncart"
                                >
                                    <Badge badgeContent={count.auctionCount} color="primary">
                                        <span className="material-icons">gavel</span>
                                    </Badge>

                                    {t('auction_cart')}
                                </ListItem>
                            </ul>
                        </Popover>
                    </>
                ) : null}
                <Button className="respNavBtn" onClick={toggleDrawer('right', true)}>
                    <span className="material-icons">menu</span>
                </Button>
                <React.Fragment>
                    <SwipeableDrawer
                        className="headerDrawer"
                        anchor={'right'}
                        open={state['right']}
                        disableBackdropTransition={!iOS}
                        disableDiscovery={iOS}
                        disableSwipeToOpen={false}
                        onClose={toggleDrawer('right', false)}
                        onOpen={toggleDrawer('right', true)}
                    >
                        <div className="headRt respNav d-flex justify-content-start align-items-center">
                            <div className="naLogoHead d-flex justify-content-between align-items-center">
                                <Link to="/">
                                    <img src={LOGO} alt="Naam" height="60" />
                                </Link>
                                <Button
                                    className="headDrawerClose"
                                    onClick={toggleDrawer('right', false)}
                                >
                                    <span className="material-icons">clear</span>
                                </Button>
                            </div>
                            <Divider />
                            <div className="headerDrawerNavLinks">
                                <ul className="navRespLinks d-flex justify-content-start align-items-center">
                                    <Link to="/" onClick={toggleDrawer('right', false)}>
                                        <ListItem button>
                                            <div>
                                                <span className="material-icons">home</span>
                                                {t('home')}
                                            </div>
                                        </ListItem>
                                    </Link>
                                    <Link to="/auction" onClick={toggleDrawer('right', false)}>
                                        <ListItem button>
                                            <div>
                                                <span className="material-icons">gavel</span>
                                                {t('live_auctions')}
                                            </div>
                                        </ListItem>
                                    </Link>

                                    <Link to="/search" onClick={toggleDrawer('right', false)}>
                                        <ListItem button>
                                            <div>
                                                <span className="material-icons">search</span>
                                                {t('auction')}
                                            </div>
                                        </ListItem>
                                    </Link>

                                    {isAuthenticated && (
                                        <div
                                            className="w-100"
                                            onClick={toggleDrawer('right', false)}
                                        >
                                            <ListItem
                                                button
                                                onClick={() => handleClose(history, 'myaccount')}
                                            >
                                                <div>
                                                    {user && user.profile_image ? (
                                                        <img
                                                            src={
                                                                global.site_url +
                                                                `/uploads/useravatar/${user.profile_image}`
                                                            }
                                                            style={{
                                                                width: '24px',
                                                                marginRight: '10px',
                                                                height: '24px',
                                                                borderRadius: '50%',
                                                            }}
                                                        />
                                                    ) : (
                                                        <span className="material-icons">
                                                            account_circle
                                                        </span>
                                                    )}

                                                    {t('my_account')}
                                                </div>
                                            </ListItem>
                                            <ListItem
                                                button
                                                onClick={() =>
                                                    handleClose(history, 'dashboard/auction')
                                                }
                                            >
                                                <div>
                                                    <span className="material-icons">gavel</span>
                                                    {t('my_auctions')}
                                                </div>
                                            </ListItem>
                                            <ListItem
                                                button
                                                onClick={() =>
                                                    handleClose(history, 'dashboard/watchlist')
                                                }
                                            >
                                                <div>
                                                    <span className="material-icons">favorite</span>
                                                    {t('watchlist')}
                                                </div>
                                            </ListItem>
                                            <ListItem
                                                button
                                                onClick={() => handleClose(history, 'invoices/all')}
                                            >
                                                <div>
                                                    <span className="material-icons">receipt</span>
                                                    {t('my_invoices')}
                                                </div>
                                            </ListItem>

                                            <ListItem
                                                button
                                                onClick={() => handleClose(history, 'saved_search')}
                                            >
                                                <div>
                                                    <span className="material-icons">bookmark</span>
                                                    {t('my_saved_searches')}
                                                </div>
                                            </ListItem>
                                            <ListItem
                                                button
                                                onClick={() => handleClose(history, 'mypoints')}
                                            >
                                                <div>
                                                    <span className="material-icons">
                                                        account_balance_wallet
                                                    </span>
                                                    {t('my_wallet')}
                                                </div>
                                            </ListItem>
                                            <ListItem
                                                button
                                                onClick={() =>
                                                    handleClose(history, 'payments/saved_cards')
                                                }
                                            >
                                                <div>
                                                    <span className="material-icons">style</span>
                                                    {t('My Cards')}
                                                </div>
                                            </ListItem>
                                        </div>
                                    )}
                                    {/* <ListItem button onClick={() => handleClose(history, 'help')}>
                                        <div>
                                            <span className="material-icons">help</span>
                                            Help Desk
                                        </div>
                                    </ListItem>

                                    <ListItem button onClick={() => handleClose(history, 'email')}>
                                        <div>
                                            <span className="material-icons">email</span>
                                            {t('email')}
                                        </div>
                                    </ListItem> */}
                                    <ListItem button className="langMobSwitch">
                                        <LanguageSwitch isMobile={true} />
                                    </ListItem>
                                    <>
                                        {isAuthenticated ? (
                                            <ListItem button onClick={() => onLogOut()}>
                                                <div>
                                                    <span className="material-icons">
                                                        power_settings_new
                                                    </span>
                                                    {t('logout')}
                                                </div>
                                            </ListItem>
                                        ) : (
                                            <li
                                                className="notLoggedLink"
                                                onClick={toggleDrawer('right', false)}
                                            >
                                                <SecondaryButton
                                                    onClick={() => handleClose(history, 'login')}
                                                    btnSize="small"
                                                    label="Log In / Sign Up"
                                                />
                                            </li>
                                        )}
                                    </>
                                </ul>
                            </div>
                        </div>
                    </SwipeableDrawer>
                </React.Fragment>
                <SwipeableDrawer
                    // modaltitle="Search"
                    // open={modalOpen}
                    // handleClose={() => toggleDirectFullScreenPopup(false)}

                    className="headerTopDrawer"
                    anchor={'top'}
                    open={state['top']}
                    disableBackdropTransition={!iOS}
                    disableDiscovery={iOS}
                    disableSwipeToOpen={true}
                    onClose={toggleDrawer('top', false)}
                    onOpen={toggleDrawer('top', true)}
                >
                    <div className="searchSlide ">
                        <div className="input-group searchHeaderInput">
                            <input
                                onKeyDown={handleSearchKeyDown}
                                className="form-control"
                                type="search"
                                placeholder={t('Search for products')}
                                aria-label="Search"
                                value={searchText}
                                onChange={handleSearchTextChange}
                                aria-describedby="searchIcon"
                            />
                            <Button
                                className="input-group-prepend"
                                onClick={handleSearchButtonClick}
                            >
                                <span className="input-group-text" id="searchIcon">
                                    <span className="material-icons">search</span>
                                </span>
                            </Button>
                        </div>
                    </div>
                </SwipeableDrawer>
                <CustomDialog
                    className="changeLocation"
                    title={'Shopping Location'}
                    open={storeLocation}
                    function={viewStoreLocation}
                >
                    <h4>You are currently shopping in</h4>
                    <p className="currLocation d-flex">
                        {currentLocation.city}, {currentLocation.state}
                    </p>
                    <Divider />
                    <div className="otherLocation">
                        <h4>Other Locations</h4>
                        {locationArray
                            .filter((x) => x.id != currentLocation.id)
                            .map((data, index) => (
                                <ListItem
                                    key={index}
                                    button
                                    onClick={() => changeLocationHandler(data)}
                                >
                                    {data.city}, {data.state}
                                </ListItem>
                            ))}
                    </div>
                    <p></p>
                </CustomDialog>
            </div>
        </div>
    )
}
export default MainHeader
