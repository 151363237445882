import React, { useContext, useEffect, useState, useRef } from 'react'
import PrimaryButton from '../../../atoms/PrimaryButton'
import {
    handleRedirectInternal,
    mapData,
    formatPhone,
    formatDate,
} from '../../../../common/components'
import AuthContext from '../../../../context/auth/authContext'
import UserContext from '../../../../context/user/userContext'
import CustomCommonContext from '../../../../../custom/context/common/commonContext'
import { Formik, useFormik } from 'formik'
import { useHistory } from 'react-router-dom'
import SecondaryButton from '../../../atoms/SecondaryButton'
import PhoneValidationComponent from '../../../species/components/user/PhoneValidationComponent'
import AddressViewComponent from '../../components/user/profile/AddressViewComponent'
import CommonContext from '../../../../context/common/commonContext'
import { initialFunction, validationFunction, editValue } from '../../dynamic/common/FieldFunction'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { Button } from '@material-ui/core'
import CheckBox from '../../../atoms/CheckBox'
import ItemPropsList from './Item'
import moment from 'moment'
import CustomDialog from '../../../organisms/Dialog'
import Cryptos from '../../../../common/cryptos'

const ProfileComponent = (props) => {
    const userContext = useContext(UserContext)
    const authContext = useContext(AuthContext)
    const customContext = useContext(CustomCommonContext)
    const commonContext = useContext(CommonContext)

    const history = useHistory()

    const { updateProfile, updatePreference, responseStatus, clearResponse } = userContext
    const { alldata_all_dropdown } = customContext
    const { allNotifications, configFeatures, allStates, allCountries, allLocations, allCities } =
        commonContext
    const { user, loadUser } = authContext
    const arrayValue = props.fieldValue
    const [phoneVerify, setPhoneVerify] = useState(false)
    const [isSecondaryBtn, setSecondaryBtn] = useState(false)
    const [initialValues, setInitialValues] = useState({})
    const [termsErrMsg, setTermsErrMsg] = useState(false)
    const [termsCheck, setTermsCheck] = useState(false)
    const [initialEmptyValues, setInitialEmptyValues] = useState({})
    const [validationArray, setValidationArray] = useState({})
    const [valueLoaded, setValueLoaded] = useState(false)
    const [mapDataValue, setMapDataValue] = useState([])
    const [mapDataValue1, setMapDataValue1] = useState([])
    const [mapDataValue2, setMapDataValue2] = useState([])
    const [deactivatePopup, setDeactivatePopup] = useState(false)
    const [arrayItemTrigger, SetArrayItemTrigger] = useState(false)
    const [itemPropsArrayErrors, SetItemPropsArrayErrors] = useState({})
    const [reload, setReload] = useState(false)
    const changePhoneVerify = () => {
        setPhoneVerify(!phoneVerify)
    }

    const [currentCountry, setCurrentCountry] = useState()

    const submitHandler = (payload) => {
        if (isSecondaryBtn) {
            updateProfile(payload, props.noAlert, 'saveExit')
        } else {
            updateProfile(payload, props.noAlert)
        }
    }

    const closeFunction = () => {
        setDeactivatePopup(false)
    }

    useEffect(() => {
        if (props.mapRef) {
            props.setMapRef(mapDataValue)
        }
    }, [mapDataValue, mapDataValue1, mapDataValue2])

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object(validationArray),
        enableReinitialize: true,
        onSubmit: (values) => {
            console.log(values, 'after submit')
            mapDataValue.map((e) => {
                if (e.type == 'date') {
                    if (props.formatCustomDate && values[e.name] && values[e.name] !== '') {
                        values[e.name] = formatDate(values[e.name])
                    } else {
                        const date = moment(
                            values[e.name],
                            global.dateFormat ? global.dateFormat : 'MM-DD-YYYY',
                        ).format('YYYY-MM-DD')
                        if (date != 'Invalid date') {
                            values[e.name] = date
                        }
                    }
                }
                if (e.type === 'hiddenMask' && values[e.name]) {
                    values[e.name] = Cryptos.encrypt(values[e.name])
                }
            })
            if (values.first_name) values.first_name = values.first_name.trim()
            if (values.last_name) values.last_name = values.last_name.trim()
            if (props.dynamicFieldTable) {
                values.table = props.dynamicFieldTable
                if (
                    values.multiArrayObject &&
                    values.multiArrayObject.length &&
                    props.dynamicFieldList.length
                ) {
                    values.multiArrayObject.map((e) => {
                        props.dynamicFieldList.map((val) => {
                            if (val.type == 'date') {
                                const date = moment(
                                    e[val.name],
                                    global.dateFormat ? global.dateFormat : 'MM-DD-YYYY',
                                ).format('YYYY-MM-DD')
                                if (date != 'Invalid date') {
                                    e[val.name] = date
                                }
                            }
                        })
                    })
                }
            }
            if (props.phoneVerify) {
                if (formik.values.phone) {
                    if (user.phone === formik.values.phone) {
                        delete values.email
                        if (props.formatPhone && props?.phoneField?.length) {
                            props?.phoneField.map((data) => {
                                if (values[data]) {
                                    values[data] = formatPhone(values[data])
                                }
                            })
                        }
                        submitHandler(values)
                        if (props.submitFunction) {
                            props.submitFunction(values)
                        }
                    } else if (
                        values.phone_verified === 1 &&
                        formik.values.verified_phonenum === formik.values.phone
                    ) {
                        delete values.email
                        if (props.formatPhone && props?.phoneField?.length) {
                            props?.phoneField.map((data) => {
                                if (values[data]) {
                                    console.log(values[data], 'valuesfgh')
                                    values[data] = formatPhone(values[data])
                                }
                            })
                        }
                        submitHandler(values)
                    } else {
                        setPhoneVerify(true)
                    }
                } else {
                    delete values.email
                    if (props.formatPhone && props?.phoneField?.length) {
                        props?.phoneField.map((data) => {
                            if (values[data]) {
                                values[data] = formatPhone(values[data])
                            }
                        })
                    }
                    submitHandler(values)
                }
            } else {
                if (props.formatPhone && props?.phoneField?.length) {
                    props?.phoneField.map((data) => {
                        if (values[data]) {
                            values[data] = formatPhone(values[data])
                        }
                    })
                }
                if (values?.license_expiry_date && values?.license_expiry_date !== '') {
                    values.license_expiry_date = moment(values.license_expiry_date).format(
                        'YYYY-MM-DD',
                    )
                }
                submitHandler(values)
            }
        },
    })

    const onAddItemArrayHandle = (option) => {
        // if (formik.values.itemPropStep === 0) {
        SetArrayItemTrigger(true)
        // }
        if (typeof formik.values.itemPropStep == 'undefined') {
            formik.values.itemPropStep = 0
        }
        formik.values.itemPropStep += 1
        formik.setFieldValue('itemPropStep', formik.values.itemPropStep)
    }

    const subStateOptions = (data) => {
        let valueDrop = allStates
            .filter(
                (state) =>
                    (formik.values.country
                        ? formik.values.country.indexOf(state.countryCode) !== -1
                        : global.defaultCountry.indexOf(state.countryCode) !== -1) &&
                    global.ignoreStates.indexOf(state.isoCode) === -1,
            )
            .map((buscat) => {
                let busCategoryChanged = {}
                busCategoryChanged.show = buscat.name
                busCategoryChanged.value = buscat.isoCode
                return busCategoryChanged
            })
        console.log(valueDrop, 'drop')
        if (global.defaultState) {
            const object1 = valueDrop.find((obj) => obj.show === global.defaultState)
            if (object1) {
                // Remove objects from their current positions
                valueDrop.splice(valueDrop.indexOf(object1), 1)
                valueDrop.unshift(object1)
            }
        }
        return valueDrop
    }

    const handleDeactivation = () => {
        termsCheck
            ? ((formik.values.status = 'deactivate'), updateProfile(formik.values))
            : setTermsErrMsg(true)
    }

    const subCityOptions = (data) => {
        let valueDrop = allCities
            .filter(
                (state) =>
                    (formik.values.country && formik.values.state
                        ? formik.values.country == state.countryCode &&
                          formik.values.state == state.stateCode
                        : global.defaultCountry.indexOf(state.countryCode) !== -1) &&
                    global.ignoreCity.indexOf(state.name) === -1,
            )
            .map((buscat) => {
                let busCategoryChanged = {}
                busCategoryChanged.show = buscat.name
                busCategoryChanged.value = buscat.name
                return busCategoryChanged
            })
        console.log(valueDrop, 'drop')
        return valueDrop
    }

    const formikPreference = useFormik({
        initialValues: {
            email_prefer: [],
            sms_prefer: [],
            termsCheck: termsCheck,
        },
        onSubmit: (values) => {
            updatePreference(values)
        },
    })

    const emailPreference = [
        {
            name: 'email_prefer',
            type: 'checkboxarray',
            class: 'col-12',
            item: allNotifications.filter((inner) => inner.email === 1),
            formik: formikPreference,
        },
    ]

    const smsPreference = [
        {
            name: 'sms_prefer',
            type: 'checkboxarray',
            class: 'col-12',
            item: allNotifications.filter((inner) => inner.sms === 1),
            formik: formikPreference,
        },
    ]

    if (props.populateValidation) {
        useEffect(() => {
            if (Object.keys(validationArray).length)
                formik.validateForm().then((errors) => {
                    if (Object.keys(errors).length) {
                        if (arrayItemTrigger) formik.handleSubmit()
                    }
                })
        }, [validationArray, arrayItemTrigger])
    }

    useEffect(() => {
        if (props.autoRegisterAfterVerify) {
            if (formik.values.phone_verified === 1) {
                formik.submitForm()
            }
        }
    }, [formik.values.phone_verified])

    useEffect(() => {
        setValueLoaded(false)
        setInitialValues({})
        setInitialEmptyValues({})
        setMapDataValue([])
        setMapDataValue1([])
        setMapDataValue2([])
        let custom = props.customInitial
        if (arrayValue.length) {
            let mapValueArray = arrayValue
            const index = null

            let { tempMapData, show } = initialFunction(
                mapValueArray,
                alldata_all_dropdown,
                subLevelOptions,
                allCountries,
                subStateOptions,
                subCityOptions,
                allLocations,
                index,
            )
            setInitialValues({ ...custom, ...show })
            setInitialEmptyValues({ ...custom, ...show })
            setMapDataValue(tempMapData)

            if (props.customInBetween) {
                const midIndex = Math.floor(props.customInBetween)
                const firstHalf = tempMapData.slice(0, midIndex)
                const secondHalf = tempMapData.slice(midIndex)
                setMapDataValue1(firstHalf)
                setMapDataValue2(secondHalf)
            }

            if (tempMapData.length) {
                setValueLoaded(true)
            }
        }
    }, [arrayValue, props.customInitial])

    const mapDataValueRef = useRef(mapDataValue)
    useEffect(() => {
        mapDataValueRef.current = mapDataValue
    })

    useEffect(() => {
        if (props.formikUpdate) {
            props.formikUpdate(formik.values)
        }
    }, [formik.values])

    useEffect(() => {
        if (mapDataValueRef.current.length) {
            let tempMapData = mapDataValueRef.current
            tempMapData.map((mapValue) => {
                if (mapValue.name == 'resale_certificate') {
                    mapValue.class = `col-12 ${formik.values.tax_number !== '' ? '' : 'd-none'}`
                }
            })
            setReload(!reload)
            // setMapDataValue(tempMapData)
            // setResetMapData(true)
        }
    }, [formik.values])

    useEffect(() => {
        if (user) {
            if (arrayValue.length) {
                const auction = user
                const index = null
                console.log(auction, 'auct')
                console.log(arrayValue, 'arr')
                let custom = props.customInitial
                setInitialValues({})
                let objectPush = editValue(
                    arrayValue,
                    auction,
                    index,
                    props.dynamicFieldTable,
                    props,
                )
                if (props.customDynamicInput) {
                    objectPush.itemPropStep = objectPush.multiArrayObject
                        ? objectPush.multiArrayObject.length
                            ? objectPush.multiArrayObject.length
                            : props.customInitialValues.itemPropStep
                        : props.customInitialValues.itemPropStep
                }
                let initialVal = { ...custom, ...objectPush }
                if (props.initialDefaultEmptyValues) {
                    props.initialDefaultEmptyValues.map((e) => {
                        if (!initialVal[e]) {
                            initialVal[e] = ''
                        }
                    })
                }
                setInitialValues(initialVal)
                if (Object.keys(objectPush).length) {
                    if (arrayItemTrigger) SetArrayItemTrigger(false)
                    setTimeout(() => {
                        SetArrayItemTrigger(true)
                    }, 1000)
                }
            }
        }
    }, [user, arrayValue])

    const subLevelOptions = (data) => {
        let valueDrop = alldata_all_dropdown
            .filter((subcat) => {
                if (
                    formik.values[data.sub_field] &&
                    parseInt(subcat.level, 10) === parseInt(data.sub_level, 10)
                ) {
                    if (
                        parseInt(subcat.active, 10) === 1 ||
                        (formik.values[data.name] && formik.values[data.name] === subcat.id)
                    ) {
                        return (
                            parseInt(formik.values[data.sub_field], 10) ===
                            parseInt(subcat.level_id, 10)
                        )
                    } else {
                        return false
                    }
                } else {
                    return false
                }
            })
            .map((subcat) => {
                let subCategoryChanged = {}
                subCategoryChanged.show = subcat.description
                subCategoryChanged.value = subcat.id
                return subCategoryChanged
            })
        return valueDrop
    }
    const { t } = useTranslation()
    const handletTermscheck = () => {
        setTermsCheck(!termsCheck)
    }
    useEffect(() => {
        setValidationArray({})

        if (arrayValue.length) {
            let mapValueArray = arrayValue
            let handle = validationFunction(mapValueArray, props.customValidation, t)
            console.log('handle', handle)
            setValidationArray(handle)
        }
    }, [arrayValue, props.customValidation])

    useEffect(() => {
        if (mapDataValue.length) {
            let tempMapData = mapDataValue
            tempMapData.map((data) => {
                if (data.type === 'select' && parseInt(data.sub_select, 10) === 1) {
                    if (parseInt(data.sub_level, 10) > 0) {
                        data.options = subLevelOptions(data)
                    }
                }
                if (data.type === 'select' && data.name === 'state') {
                    data.options = subStateOptions(data)
                }
                if (data.type === 'select' && data.name === 'city') {
                    data.options = subCityOptions(data)
                }
            })
        }
    }, [formik.values])

    useEffect(() => {
        if (props.additionalfieldUpdate) {
            props.additionalfieldUpdate(formik.values)
        }
    }, [formik.values])

    useEffect(() => {
        if (responseStatus) {
            console.log(responseStatus, 'tyyy')
            if (responseStatus.from === 'updateProfile') {
                if (responseStatus.status === 'success') {
                    if (props.onSuccess) {
                        props.onSuccess()
                    }
                    loadUser()
                }
            } else if (responseStatus.from === 'saveExit') {
                if (responseStatus.status === 'success') {
                    if (props.onFormSaveExit) {
                        props.onFormSaveExit()
                    }
                    loadUser()
                }
            }
            clearResponse()
        }
    }, [responseStatus])

    return (
        <>
            {valueLoaded ? (
                <form onSubmit={formik.handleSubmit} autoComplete="nofill">
                    <div className="accChild">
                        {props.showHeadText ? (
                            <> {props.headText ? <h4>{props.headText}</h4> : <h4>Profile </h4>} </>
                        ) : null}
                        {props.customInBetween ? (
                            <>
                                <div className="row">
                                    {mapData({ formik, data: mapDataValue1 })}
                                </div>
                                {props.customDynamicInput ? (
                                    <>
                                        <div className="row">
                                            <ItemPropsList
                                                formik={formik}
                                                trigger={arrayItemTrigger}
                                                errors={itemPropsArrayErrors}
                                                resetTrigger={SetArrayItemTrigger}
                                                alldata_all_dropdown={alldata_all_dropdown}
                                                allCountries={allCountries}
                                                subStateOptions={subStateOptions}
                                                subCityOptions={subCityOptions}
                                                allLocations={allLocations}
                                                isDelete={props.isDelete}
                                                disableEditing={props.disableEditing}
                                            />
                                        </div>
                                        <div className="row">
                                            <div className="col-12 moreBtnPRT">
                                                <Button
                                                    className="addMore"
                                                    type="button"
                                                    onClick={() => onAddItemArrayHandle(1)}
                                                >
                                                    <span className="material-icons">
                                                        add_circle
                                                    </span>
                                                    {t('Add a Representative')}
                                                </Button>
                                            </div>
                                        </div>
                                    </>
                                ) : null}
                                {mapDataValue2.length ? (
                                    <div className="row">
                                        {mapData({ formik, data: mapDataValue2 }, false, false, t)}
                                    </div>
                                ) : null}
                            </>
                        ) : (
                            <>
                                <div className="row">{mapData({ formik, data: mapDataValue })}</div>
                                {props.customDynamicInput ? (
                                    <>
                                        <div className="row">
                                            <ItemPropsList
                                                formik={formik}
                                                trigger={arrayItemTrigger}
                                                errors={itemPropsArrayErrors}
                                                resetTrigger={SetArrayItemTrigger}
                                                alldata_all_dropdown={alldata_all_dropdown}
                                                allCountries={allCountries}
                                                subStateOptions={subStateOptions}
                                                subCityOptions={subCityOptions}
                                                allLocations={allLocations}
                                                isDelete={props.isDelete}
                                                disableEditing={props.disableEditing}
                                            />
                                        </div>
                                        <div className="row">
                                            <div className="col-12 moreBtnPRT">
                                                <Button
                                                    className="addMore"
                                                    type="button"
                                                    onClick={() => onAddItemArrayHandle(1)}
                                                >
                                                    <span className="material-icons">
                                                        add_circle
                                                    </span>
                                                    {t('Add a Representative')}
                                                </Button>
                                            </div>
                                        </div>
                                    </>
                                ) : null}
                            </>
                        )}
                    </div>
                    {props.customBtnGroup ? (
                        <div className="customBtnGroup">{props.customBtnGroup}</div>
                    ) : (
                        <div className="d-flex justify-content-center align-items-center">
                            <div className="profileAct">
                                {props.onFormBack ? (
                                    <SecondaryButton
                                        id="profileAct"
                                        onClick={props.onFormBack ? props.onFormBack : null}
                                        disabled={
                                            props.backBtnDisabled ? props.backBtnDisabled : null
                                        }
                                        label={props.BackBtnLabel ? props.BackBtnLabel : 'Prev'}
                                    />
                                ) : (
                                    ''
                                )}
                                {props.SaveExitBtnLabel ? (
                                    <SecondaryButton
                                        id="btnSkip"
                                        className="btnSkip"
                                        onClick={() => {
                                            formik.handleSubmit()
                                            setSecondaryBtn(true)
                                        }}
                                        label={
                                            props.SaveExitBtnLabel
                                                ? props.SaveExitBtnLabel
                                                : 'Save & Exit'
                                        }
                                    />
                                ) : (
                                    ''
                                )}
                                <PrimaryButton
                                    id="profileBtn"
                                    onClick={() => {
                                        formik.submitForm()
                                        setSecondaryBtn(false)
                                    }}
                                    disabled={
                                        props.successBtnDisabled ? props.successBtnDisabled : false
                                    }
                                    label={props.SubmitBtnLabel ? props.SubmitBtnLabel : 'Save'}
                                />
                            </div>
                            {props.onFormSkip ? (
                                <Button
                                    id="profilePage_skip"
                                    className="btnSkip"
                                    onClick={props.onFormSkip}
                                >
                                    {props.SkipBtnLabel ? props.SkipBtnLabel : 'Skip'}
                                </Button>
                            ) : (
                                ''
                            )}
                        </div>
                    )}

                    {props.deactiveEnable ? (
                        <div className="deleteAccCnt">
                            <Button
                                id="deactivate_button"
                                onClick={() => setDeactivatePopup(true)}
                                className="deleteAccount"
                            >
                                <span className="material-icons-outlined">delete</span>
                                {t('Deactivate')}
                            </Button>
                        </div>
                    ) : null}

                    {props.addressDetails ? (
                        <AddressViewComponent validateAddress={props.validateAddress || false} />
                    ) : null}
                    {props.phoneVerify ? (
                        <PhoneValidationComponent
                            phoneVerify={phoneVerify}
                            formik={formik}
                            setPhoneVerify={setPhoneVerify}
                            changePhoneVerify={changePhoneVerify}
                        />
                    ) : null}
                </form>
            ) : null}
            <CustomDialog
                className="bidAskPopup prflAlrtPopup"
                open={deactivatePopup}
                function={() => setDeactivatePopup(false)}
                maxWidth={'sm'}
                // title={t('Deactivate')}
                handleClose={closeFunction}
            >
                <div className="modalHeader">
                    <div className="">
                        <h4 className="tit">¡Atención!</h4>
                        <h2>Cancelar Mi Cuenta</h2>
                    </div>
                </div>
                <div className="icnCrclCntnr">
                    <div className="d-flex">
                        <span className="material-icons">error</span>
                        <h4>{t('Are you sure you want to Deactivate your account?')}</h4>
                    </div>
                    <CheckBox
                        name="terms"
                        id="terms"
                        value="condition"
                        checked={termsCheck}
                        onChange={handletTermscheck}
                        label="Acepto las condiciones de uso y la Información básica de Protección de Datos"
                    />
                    {!termsCheck && termsErrMsg && (
                        <p className="text-danger ml-5">{t('Required')}</p>
                    )}
                </div>
                <div className="btnStack">
                    <SecondaryButton
                        btnSize="medium"
                        onClick={() => setDeactivatePopup(false)}
                        label={t('No')}
                    />
                    <PrimaryButton
                        btnSize="medium"
                        onClick={() => {
                            if (termsCheck) handleDeactivation()
                            else setTermsErrMsg(true)
                        }}
                        label={t('Yes')}
                    />
                </div>
            </CustomDialog>
        </>
    )
}

export default ProfileComponent
