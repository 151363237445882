import React, { useState, useContext, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import openSocket from 'socket.io-client'
import { useFormik } from 'formik'
import { Button, MenuItem } from '@material-ui/core'
import { Pagination } from '@material-ui/lab'

import CustomSelect from '@/product/components/atoms/Inputs/CustomSelect'
import CustomInput from '@/product/components/atoms/Inputs/CustomInput'
import AuthContext from '@/product/context/auth/authContext'
import AlertContext from '@/product/context/alert/alertContext'
import AuctionCard from '@/custom/components/molecules/ProductCard/AuctionCard'
import AuctionContext from '@/product/context/auction/auctionContext'
import { messageHandler } from '@/product/common/socketHandler'
import { mapData, useCustomMediaQuery } from '@/product/common/components'
import { useTranslation } from 'react-i18next'

import NoRecordsFound from '@/custom/components/atoms/NoRecordsFound'
import Loaders from '@/custom/components/molecules/Loaders'
import './auction.css'
import SpecialEvent from '@/custom/components/molecules/ProductCard/SpecialEvent'

// import SEO from '@/utils/SEO'

const auction = (props) => {
    const { search_allauctions, getAllAuctionProducts } = useContext(AuctionContext)
    const { user } = useContext(AuthContext)
    const { setAlert } = useContext(AlertContext)
    const { t } = useTranslation()

    const pageOptions = props.pageOptions
    const sortShow = props.sortShow
    const search = props.search

    let [auctions, setAuctions] = useState([])
    let [viewProduct, setViewProduct] = useState([])
    const [isLoading, setIsLoading] = useState(true)

    const viewProductRef = useRef(viewProduct)
    const userRef = useRef(user)

    const formik = useFormik({
        initialValues: search.FormikSearch,
    })
    const onHandlePageAuction = (event, value) => {
        formik.setFieldValue(`page`, value)
    }

    // handler
    const handler = (message, type) => {
        messageHandler(
            message,
            viewProductRef.current,
            userRef.current,
            setAlert,
            setViewProduct,
            type,
        )
    }

    useEffect(() => {
        const socket = openSocket(`${process.env.REACT_APP_DOMAIN}/`, { transports: ['websocket'] })
        socket.on('realclosedupdates', (data) => {
            handler(data, 'realclosedupdates')
        })
        socket.on('bidAddtime', (data) => {
            handler(data, 'bidAddtime')
        })
        socket.on('cancelBidEmitted', (data) => {
            formikSearch.values.filters.auctionid.value = [Number(props.match.params.aid)]
            getAllSearchProducts(formikSearch.values, 'searchproducts')
        })
        return () => {
            socket.off('realclosedupdates', (data) => {
                handler(data, 'realclosedupdates')
            })
            socket.off('bidAddtime', (data) => {
                handler(data, 'bidAddtime')
            })
            socket.off('cancelBidEmitted', (data) => {})
            socket.disconnect()
        }
    }, [])
    const handleChange = (e) => {
        const { name, value } = e.currentTarget
        console.log(name, value, 'sdfasdf')
        formik.values.filters[name] = value
    }
    useEffect(() => {
        setIsLoading(true)
        getAllAuctionProducts(formik.values)
    }, [formik.values])

    useEffect(() => {
        setAuctions(search_allauctions.records.length ? search_allauctions.records : [])
        setIsLoading(false)
    }, [search_allauctions])

    return (
        <>
            {/* <SEO title="Live Auctions" url={window.location.href} /> */}

            <div className="auctionListCnt d-flex justify-content-start align-items-start">
                <section className="w-100">
                    <div className="searchHead">
                        <div className="alTitle">
                            <h2>{t('auctions')}</h2>
                            <h6>
                                {search_allauctions.totalRecords} {t('results_found')}
                            </h6>
                        </div>
                        <div className="searchMiscFilters w-100 mb-3 d-flex justify-content-end align-items-end">
                            {props.searchbytitle && (
                                <CustomInput
                                    label="Auction "
                                    className="auctioninput"
                                    placeholder="Search"
                                    size="small"
                                    onChange={(e) => {
                                        formik.setFieldValue('filters.title.value', e.target.value)
                                    }}
                                />
                            )}
                            <CustomSelect
                                label={t('results_per_page')}
                                name="resultsPerPage"
                                selectType="noBorder"
                                size="small"
                            >
                                {pageOptions.map((opt, optindex) => (
                                    <option value={opt.value} key={optindex}>
                                        {opt.show}
                                    </option>
                                ))}
                            </CustomSelect>
                            <CustomSelect
                                label={t('sort_by')}
                                value={formik.values.orderby}
                                size="small"
                                selectType="noBorder"
                                name="orderby"
                                // shrink={search.orderby !== '' ? true : false}
                                onChange={(event, editor) => {
                                    formik.setFieldValue(`orderby`, event.target.value)
                                }}
                            >
                                {sortShow.map((opt, optindex) => (
                                    <option value={opt.value}>{opt.show}</option>
                                ))}
                            </CustomSelect>
                        </div>
                    </div>
                    {isLoading ? (
                        <Loaders name="product_list_view" isLoading={isLoading} />
                    ) : auctions.length ? (
                        <div>
                            <div className="auctionList">
                                {auctions.map((data, index) => (
                                    <SpecialEvent data={data} />
                                ))}
                            </div>

                            <div className="d-flex justify-content-between align-items-center flex-wrap w-100 my-3 pagination-wrapper">
                                <h6>
                                    {t('showing')} {search_allauctions.setDisp} {t('of')}{' '}
                                    {search_allauctions.totalRecords}
                                </h6>
                                <Pagination
                                    count={Math.ceil(
                                        search_allauctions.totalRecords / formik.values.limit,
                                    )}
                                    page={formik.values.page}
                                    onChange={onHandlePageAuction}
                                    siblingCount={3}
                                    showFirstButton
                                    showLastButton
                                    boundaryCount={2}
                                />
                            </div>
                        </div>
                    ) : (
                        <NoRecordsFound />
                    )}
                </section>
            </div>
        </>
    )
}
export default auction
