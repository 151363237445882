import React, { useReducer } from 'react'
import PayGccContext from './hyperPayContext'
import PayGccReducer from './hyperPayReducer'
import { apiCall } from '@/product/common/api'
import { response } from '@/product/context/common'
import { RESPONSE_STATUS, CLEAR_RESPONSE, GET_CARDS } from './hyperPayTypes'

const PayGccState = (props) => {
    const initialState = {
        responseStatus: null,
        all_cards: null,
    }

    const [state, dispatch] = useReducer(PayGccReducer, initialState)
    let resp = new response(dispatch, RESPONSE_STATUS)

    const payCart = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'hyperpay_payment', formData, '', 'payment'),
            ])
            resp.commonResponse(res.data, 'payment')
        } catch (err) {
            resp.commonErrorResponse('payment')
        }
    }

    const payResponse = async (formData) => {
        const from = 'hyperpay_response'
        try {
            const [res] = await Promise.all([apiCall('post', from, formData, '', 'payment')])
            resp.commonResponse(res.data, from)
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const createCardToken = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'createCardToken', formData, '', 'paymentThird/paygcc'),
            ])

            resp.commonResponse(res.data, 'create_card_token')
        } catch (err) {
            resp.commonErrorResponse('create_card_token')
        }
    }

    const createCardTokenResponse = async (formData) => {
        const from = 'create_card_response'
        try {
            const [res] = await Promise.all([
                apiCall('post', 'createCardTokenResponse', formData, '', 'paymentThird/hyperpay'),
            ])
            resp.commonResponse(res.data, from)
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const getCardTokens = async (formData, noAlert, from) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'getCardTokens', formData, '', 'paymentThird/paygcc'),
            ])

            if (res.data.status === 'success') {
                dispatch({
                    type: GET_CARDS,
                    payload: res.data.data.responseData,
                })
            }
        } catch (err) {
            resp.commonErrorResponse('fetchcard')
            // dispatch({
            //     type: GET_BPOINT_CARDS,
            //     payload: 'Unable to fetch cards',
            // })
        }
    }

    const deleteCardToken = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'deleteCardToken', formData, '', 'paymentThird/paygcc'),
            ])
            resp.commonResponse(res.data, 'deleteCard')
        } catch (err) {
            resp.commonErrorResponse('deleteCard')
        }
    }

    const clearResponse = () =>
        dispatch({
            type: CLEAR_RESPONSE,
        })

    return (
        <PayGccContext.Provider
            value={{
                responseStatus: state.responseStatus,
                all_cards: state.all_cards,
                payCart,
                payResponse,
                getCardTokens,
                createCardTokenResponse,
                deleteCardToken,
                createCardToken,
                clearResponse,
            }}
        >
            {props.children}
        </PayGccContext.Provider>
    )
}

export default PayGccState
