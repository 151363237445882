import React, { useState, useEffect } from 'react'
import { socket } from './socket'
import moment from 'moment'
// import AuthContext from '../context/auth/authContext';
import { useTranslation } from 'react-i18next'
let serverTime = new Date()

const getTimeRemaining = (t) => {
    var seconds = ('0' + Math.floor((t / 1000) % 60)).slice(-2)
    var minutes = ('0' + Math.floor((t / 1000 / 60) % 60)).slice(-2)
    var hours = ('0' + Math.floor((t / (1000 * 60 * 60)) % 24)).slice(-2)
    var days = 0
    if (Math.floor(t / (1000 * 60 * 60 * 24)).toString().length >= 2) {
        days = Math.floor(t / (1000 * 60 * 60 * 24))
    } else {
        days = ('0' + Math.floor(t / (1000 * 60 * 60 * 24))).slice(-2)
    }
    return {
        total: t,
        days: days,
        hours: hours,
        minutes: minutes,
        seconds: seconds,
    }
}

const Timer = (props) => {
    // const authContext = useContext(AuthContext);
    // const { user } = authContext;
    const { t } = useTranslation()
    const [timer, setTimer] = useState({
        timervalid: false,
        timer_id: 0,
        days: 0,
        seconds: 0,
        minutes: 0,
        hours: 0,
        day: '',
        hours12: 0,
        meridiem: '',
        thours: 0,
        thours12: 0,
        tminutes: 0,
        tmeridiem: '',
    })

    useEffect(() => {
        if (socket) {
            socket.on('servertime', (data) => {
                if (moment(data.dTime).isValid()) {
                    serverTime = new Date(data.dTime)
                }
            })
        }
    }, [socket])

    const checkProductOpen = (date_added) => {
        let startDate = new Date(serverTime)
        let addDate = new Date(date_added)
        let milliSeconds = 0
        let incrementedTime = addDate.getTime() + parseInt(milliSeconds)
        let newaddDate = new Date(incrementedTime)
        let response = false
        if (newaddDate > startDate) {
            response = false
        } else {
            response = true
        }
        return response
    }

    const checkProductClose = (date_closed) => {
        let serverDate = new Date(serverTime)
        let closeDate = new Date(date_closed)
        let milliSeconds = 0
        let incrementedTime = closeDate.getTime() + parseInt(milliSeconds)
        let newCloseDate = new Date(incrementedTime)
        let response = false
        if (serverDate < newCloseDate) {
            response = false
        } else {
            response = true
        }
        console.log('product.past_inactive checkProductClose', response)
        return response
    }

    useEffect(() => {
        if (
            props.date_closed &&
            moment(props.date_closed).isValid() &&
            props.date_added &&
            moment(props.date_added).isValid()
        ) {
            const interval = setInterval(() => {
                let startDate = new Date(serverTime)
                let endDate = new Date(props.date_closed)
                let addDate = new Date(props.date_added)
                let timerTime = endDate.getTime() - startDate.getTime()
                let future = false
                let milliSeconds = 0
                const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
                let currentDay = ''
                let thours = 0
                let thours12 = 0
                let tmeridiem = ''
                let tminutes = 0
                // if (parseInt(user.role) !== 3) {
                //   milliSeconds = 1 * 3600000;
                // } else {
                // }
                let incrementedTime = addDate.getTime() + parseInt(milliSeconds)
                let newaddDate = new Date(incrementedTime)
                if (newaddDate > startDate) {
                    timerTime = incrementedTime - startDate.getTime()
                    future = true
                }

                if (props.date_added_check) {
                    if (checkProductOpen(props.date_added)) {
                        if (!future) {
                            if (props && props.date_added_reached) {
                                props.date_added_reached(true)
                            }
                        }
                    }
                }
                var t = getTimeRemaining(timerTime)

                if (props.date_closed_check) {
                    if (checkProductClose(props.date_closed)) {
                        if (props && props.date_closed_reached) {
                            props.date_closed_reached(true)
                        }
                    }
                }

                if (future) {
                    const currentDayIndex = addDate.getDay()
                    currentDay = daysOfWeek[currentDayIndex]
                    thours = addDate.getHours()
                    thours12 = parseInt(thours, 10) % 12 || 12
                    tmeridiem = parseInt(thours, 10) < 12 ? 'AM' : 'PM'
                    tminutes = addDate.getMinutes()
                    tminutes = String(tminutes).padStart(2, '0')
                } else {
                    const currentDayIndex = endDate.getDay()
                    currentDay = daysOfWeek[currentDayIndex]
                    thours = endDate.getHours()
                    thours12 = parseInt(thours, 10) % 12 || 12
                    tmeridiem = parseInt(thours, 10) < 12 ? 'AM' : 'PM'
                    tminutes = endDate.getMinutes()
                    tminutes = String(tminutes).padStart(2, '0')
                }
                let hours12 = parseInt(t.hours, 10) % 12 || 12

                const meridiem = parseInt(t.hours, 10) < 12 ? 'AM' : 'PM'

                if (t.days < 2) {
                    if (typeof props.alertListener !== 'undefined') props.alertListener()
                }
                if (t.total <= 0) {
                    if (t.future) {
                    } else {
                        setTimer({
                            ...timer,
                            timer_id: interval,
                            timervalid: false,
                        })
                        clearInterval(interval)
                    }
                } else {
                    setTimer({
                        ...timer,
                        timer_id: interval,
                        timervalid: true,
                        days: t.days,
                        seconds: t.seconds,
                        minutes: t.minutes,
                        hours: t.hours,
                        future: future,
                        day: currentDay,
                        hours12: hours12,
                        meridiem: meridiem,
                        thours: thours,
                        tminutes: tminutes,
                        thours12: thours12,
                        tmeridiem: tmeridiem,
                    })
                }
            }, 1000)
            return () => {
                clearInterval(interval)
            }
        } else if (props.date_closed && moment(props.date_closed).isValid()) {
            const interval = setInterval(() => {
                var startDate = new Date(serverTime)
                var endDate = new Date(props.date_closed)
                var timerTime = endDate.getTime() - startDate.getTime()
                var t = getTimeRemaining(timerTime)
                if (t.days < 2) {
                    if (typeof props.alertListener !== 'undefined') props.alertListener()
                }
                if (t.total <= 0) {
                    setTimer({
                        ...timer,
                        timer_id: interval,
                        timervalid: false,
                    })
                    clearInterval(interval)
                } else {
                    setTimer({
                        ...timer,
                        timer_id: interval,
                        timervalid: true,
                        days: t.days,
                        seconds: t.seconds,
                        minutes: t.minutes,
                        hours: t.hours,
                    })
                }
            }, 1000)
            return () => {
                clearInterval(interval)
            }
        }
    }, [props])
    return (
        <>
            {timer.timer_id === 0 ? (
                ''
            ) : timer.timervalid ? (
                <>
                    {props.withText === 1 ? (
                        timer.future ? (
                            <h6>{t('starts_in')}</h6>
                        ) : (
                            <h6>{t('time_left')}</h6>
                        )
                    ) : (
                        ''
                    )}
                    <p>
                        {timer.days > 0
                            ? `${timer.day} ${timer.thours12}:${timer.tminutes} ${timer.tmeridiem}`
                            : `${timer.hours} ${t('hrs')} ${timer.minutes} ${t('mins')}`}
                    </p>
                </>
            ) : (
                <span className="closedText">
                    {props.closedText ? props.closedText : 'Auction Closed'}
                </span>
            )}
        </>
    )
}

export default Timer
