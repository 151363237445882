import React, { useReducer } from 'react'
import axios from 'axios'
import CardContext from './cardContext'
import CardReducer from './cardReducer'
import { response } from '../../../common'
import { DirectAPICAll } from '../../../../common/components'

import {
    GET_ALL_CARD_DETAILS,
    GET_CARD_DETAILS,
    RESPONSE_STATUS,
    CLEAR_RESPONSE,
} from './cardTypes'

const StripeCardState = (props) => {
    const initialState = {
        get_all_card_details: null,
        get_card_details: null,
        responseStatus: null,
    }
    const [state, dispatch] = useReducer(CardReducer, initialState)
    let resp = new response(dispatch, RESPONSE_STATUS)

    //Skyflow
    const createCard = async (formData) => {
        try {
            const res = await DirectAPICAll(
                'post',
                `${process.env.REACT_APP_DOMAIN}/api/paymentThird/hyperpay/createCardToken`,
                formData,
            )

            resp.commonResponse(res.data, 'card_source_create')
        } catch (err) {
            resp.commonErrorResponse('card_source_create')
        }
    }

    const getAllHyperPayCards = async (data) => {
        try {
            const res = await DirectAPICAll(
                'post',
                `${process.env.REACT_APP_DOMAIN}/api/paymentThird/hyperpay/retrieveCardToken`,
                data,
            )
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_CARD_DETAILS,
                    payload: {
                        records: res.data.data.responseData.data.length
                            ? res.data.data.responseData.data
                            : [],
                        has_more: res.data.data.responseData.has_more,
                    },
                })
            } else {
                resp.commonResponse(res, 'card_source_list')
            }
        } catch (err) {
            console.log('errr', err)
            resp.commonErrorResponse('card_source_list')
        }
    }

    const updateCard = async (data) => {
        try {
            const res = await DirectAPICAll(
                'post',
                `${process.env.REACT_APP_DOMAIN}/api/customer/source/update`,
                data,
            )
            resp.commonResponse(res.data, 'card_source_update')
        } catch (err) {
            console.log('errr', err)
            resp.commonErrorResponse('card_source_update')
        }
    }

    const defaultCard = async (data) => {
        try {
            const res = await DirectAPICAll(
                'post',
                `${process.env.REACT_APP_DOMAIN}/api/paymentThird/hyperpay/defaultCardToken`,
                data,
            )
            resp.commonResponse(res.data, 'card_source_update')
        } catch (err) {
            console.log('errr', err)
            resp.commonErrorResponse('card_source_update')
        }
    }

    const deleteCard = async (data) => {
        try {
            const res = await DirectAPICAll(
                'post',
                `${process.env.REACT_APP_DOMAIN}/api/paymentThird/hyperpay/deleteCardToken`,
                data,
            )
            resp.commonResponse(res.data, 'card_source_delete')
        } catch (err) {
            console.log('errr', err)
            resp.commonErrorResponse('card_source_delete')
        }
    }

    const clearResponse = () =>
        dispatch({
            type: CLEAR_RESPONSE,
        })

    return (
        <CardContext.Provider
            value={{
                responseStatus: state.responseStatus,
                get_all_card_details: state.get_all_card_details,
                get_card_details: state.get_card_details,
                createCard,
                getAllHyperPayCards,
                updateCard,
                defaultCard,
                deleteCard,
                clearResponse,
            }}
        >
            {props.children}
        </CardContext.Provider>
    )
}

export default StripeCardState
