import React, { useState, useContext, useEffect } from 'react'
import './Login.css'
import { useHistory } from 'react-router-dom'
import PrimaryButton from '../../product/components/atoms/PrimaryButton'
import SecondaryButton from '../../product/components/atoms/SecondaryButton'
import { Link } from 'react-router-dom'
import { Button } from '@material-ui/core'
import { mapData, handleRedirectInternal } from '../../product/common/components'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import AuthContext from '../../product/context/auth/authContext'
import AlertContext from '../../product/context/alert/alertContext'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { useGoogleLogin, GoogleLogin } from '@react-oauth/google'
import { useTranslation } from 'react-i18next'
import jwtDecode from 'jwt-decode'
import PhoneValidationComponent from '../../product/components/species/components/user/PhoneValidationComponent'
const Login = () => {
    const history = useHistory()
    const authContext = useContext(AuthContext)
    const alertContext = useContext(AlertContext)
    const { t } = useTranslation()
    const { setAlert } = alertContext
    const [phoneVerify, setPhoneVerify] = useState(false)
    const { login, responseStatus, clearResponse, isAuthenticated } = authContext
    const changePhoneVerify = () => {
        setPhoneVerify(!phoneVerify)
    }
    useEffect(() => {
        if (isAuthenticated) {
            handleRedirectInternal(history, '')
        }
    }, [isAuthenticated])

    let [passwordShown, setPasswordShown] = useState(false)
    let [isLoginPhone, setIsLoginPhone] = useState(false)
    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true)
    }

    const validationArray = Yup.object({
        email: Yup.string().email(t('invalid_email_format')).required(t('required')),
        facebook_id: Yup.string(),
        google_id: Yup.string(),
        password: Yup.string().when(['facebook_id', 'google_id'], {
            is: (facebookID, googleID) => !facebookID && !googleID,
            then: Yup.string().required(t('required')),
        }),
    })
    const validationArrayPhone = Yup.object({
        phone: Yup.string()
            .trim()
            .matches(/^[0-9- +()]*$/g, t('Only numbers are allowed'))
            .max(17, t('maximum_10_charaters'))
            .min(11, t('Invalid Phone Number'))
            .required(t('Required!')),
    })

    const formik = useFormik({
        initialValues: {
            email: localStorage.email ? localStorage.email : '',
            password: localStorage.password ? localStorage.password : '',
            google_id: '',
            facebook_id: '',
            remember_me: localStorage.remember_me ? localStorage.remember_me : false,
            type: '1',
            phone: localStorage.phone ? localStorage.phone : '',
        },
        validationSchema: isLoginPhone ? validationArrayPhone : validationArray,
        onSubmit: (values) => {
            if (values.remember_me) {
                localStorage.email = values.email
                localStorage.password = values.password
                localStorage.remember_me = values.remember_me
                localStorage.phone = values.phone
            } else {
                delete localStorage.email
                delete localStorage.password
                delete localStorage.remember_me
                delete localStorage.phone
            }
            if (isLoginPhone) {
                changePhoneVerify()
            } else {
                login(values)
            }
        },
    })
    useEffect(() => {
        setIsLoginPhone(formik.values.type === '1' ? false : true)
    }, [formik.values.type])
    const rememberMe = [
        {
            label: t('remember_me'),
            name: 'remember_me',
            type: 'checkbox',
            placeholder: t('remember_me'),
            class: 'col-sm-7 col-auto',
            formik: formik,
        },
    ]

    const loginInfo = [
        {
            label: t('email_address'),
            name: 'email',
            type: 'email',
            placeholder: t('enter_your_email_address'),
            class: 'col-12',
            // autoFocus: true,
            formik: formik,
        },
        {
            label: t('password'),
            name: 'password',
            type: passwordShown ? 'text' : 'password',
            placeholder: t('enter_your_password'),
            class: 'col-12',
            formik: formik,
            endAdornment: passwordShown ? (
                <span className="material-icons cursorPointer" onClick={togglePasswordVisiblity}>
                    visibility_off
                </span>
            ) : (
                <span className="material-icons cursorPointer" onClick={togglePasswordVisiblity}>
                    visibility
                </span>
            ),
        },
    ]
    const loginInfoPhone = [
        {
            label: t('phone'),
            name: 'phone',
            type: 'phone',
            placeholder: t('phone'),
            class: 'col-12',
            // autoFocus: true,
            formik: formik,
        },
    ]
    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'login') {
                setAlert(responseStatus.message, responseStatus.status)
                clearResponse()
                if (responseStatus.status === 'success') {
                    let value = sessionStorage.getItem('productID')
                    handleRedirectInternal(history, value ? `productView/${value}` : 'search')
                    sessionStorage.removeItem('productID')
                }
            }
        }
    }, [responseStatus])

    const responseFacebook = (response) => {
        formik.values.email = response.email
        formik.values.facebook_id = response.id
        login(formik.values)
    }

    const responseGoogle = (response) => {
        console.log('glrespose=====>', response)
        const userDetails = jwtDecode(response.credential)
        console.log(userDetails, 'detiii')
        formik.values.email = userDetails.email
        formik.values.google_id = userDetails.jti
        login(formik.values)
    }
    const checkinData = [
        {
            title: t('Login With'),
            type: 'radio',
            name: 'type',
            item: [
                { id: '1', description: t('email') },
                { id: '2', description: t('phone_number') },
            ],
            class: 'col-12',
            formik: formik,
            int: 1,
        },
    ]

    return (
        <>
            {' '}
            <PhoneValidationComponent
                phoneVerify={phoneVerify}
                formik={formik}
                setPhoneVerify={setPhoneVerify}
                changePhoneVerify={changePhoneVerify}
                isLogin={true}
            />
            <div className="login">
                <div className="container loginCnt">
                    <div className="loginBox d-flex justify-content-center align-items-start">
                        <div className="loginLt">
                            <h1>{t('login')}</h1>
                            <div className="socialButtons d-flex justify-content-between align-items-center">
                                {/* <GoogleLogin
                                clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                                onSuccess={responseGoogle}
                                cookiePolicy={'single_host_origin'}
                                render={(renderProps) => (
                                    <Button
                                        variant="outlined"
                                        onClick={renderProps.onClick}
                                        disabled={renderProps.disabled}
                                        color="default"
                                    >
                                        <object
                                            aria-label="Google Login"
                                            data="/assets/svg/google.svg"
                                            type="image/svg+xml"
                                        ></object>
                                        Login with Google
                                    </Button>
                                )}
                            /> */}
                                <GoogleLogin
                                    text={t('signin_with')}
                                    onSuccess={(credentialResponse) => {
                                        responseGoogle(credentialResponse)
                                    }}
                                    onError={() => {
                                        console.log('Login Failed')
                                    }}
                                />
                                {/* <FacebookLogin
                                appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                                fields="name,email"
                                callback={responseFacebook}
                                render={(renderProps) => (
                                    <Button
                                        onClick={renderProps.onClick}
                                        variant="outlined"
                                        className="fbLogin"
                                        color="default"
                                    >
                                        <object
                                            aria-label="Facebook Login"
                                            data="/assets/svg/facebook.svg"
                                            type="image/svg+xml"
                                        ></object>
                                        <span> {t('Login with Facebook')}</span>
                                    </Button>
                                )}
                            /> */}
                            </div>
                            <p className="loginDivider">{t('OR')}</p>
                            <div className="row">{Object.values(mapData(checkinData))}</div>
                            {formik.values.type === '2' ? (
                                <>
                                    <div className="row">
                                        {Object.values(mapData(loginInfoPhone))}
                                    </div>
                                    <div className="row">{Object.values(mapData(rememberMe))}</div>

                                    <PrimaryButton
                                        onClick={formik.handleSubmit}
                                        label={t('login')}
                                        btnSize="large"
                                    />
                                </>
                            ) : (
                                <form onSubmit={formik.handleSubmit}>
                                    <div className="row">{Object.values(mapData(loginInfo))}</div>
                                    <div className="row">
                                        {Object.values(mapData(rememberMe))}
                                        <div className="col-sm-5 col-auto">
                                            <Link className="fpLink" to="/forgot_password">
                                                {t('forgot_password')}
                                            </Link>
                                        </div>
                                    </div>

                                    <PrimaryButton
                                        label={t('login')}
                                        type="submit"
                                        btnSize="large"
                                    />
                                </form>
                            )}
                        </div>
                        <div className="loginRt">
                            <div>
                                <h1>
                                    {t('New to')}
                                    <br /> {t('Naam')}?
                                </h1>
                                <p>{t('Sign up for a free account in no time')}</p>
                            </div>
                            <div
                                // onClick={() => handleRedirectInternal(history, 'registration')}
                                className="regIcon"
                            >
                                <object data="/assets/svg/regIcon.svg" type="image/svg+xml" />
                            </div>
                            <SecondaryButton
                                onClick={() => handleRedirectInternal(history, 'registration')}
                                label={t('Create an Account')}
                                btnSize="large"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Login
