import { Button } from '@material-ui/core'
import React, { useState, useRef, useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import './ProductCard.css'
import FavouriteCheckbox from '../../../../custom/components/atoms/FavoriteCheckbox'
import PrimaryButton from '../../../../custom/components/atoms/PrimaryButton'
import SecondaryButton from '../../../../product/components/atoms/SecondaryButton'

import Bidding from '@/custom/components/molecules/Bidding/BiddingItem'
import Buynow from '../../molecules/Buynow/BuynowItem'
import AuthContext from '../../../../product/context/auth/authContext'
import { dateFormatFront, handleRedirectInternal, currencyFormat } from '../../../common/components'
import CommonContext from '../../../context/common/commonContext'
import CommonContextt from '../../../../product/context/common/commonContext'

import { socket } from '@/product/common/socket'
import { useTranslation } from 'react-i18next'
import Timer from '@/custom/common/timer'
import moment from 'moment'
import { Badge, Divider, ListItem, Menu, MenuItem, SwipeableDrawer } from '@material-ui/core'
import CopyToClipboard from 'react-copy-to-clipboard'
import AlertContext from '@/product/context/alert/alertContext'
import { FacebookShareButton } from 'react-share'
import { messageHandler } from '@/product/common/socketHandler'
import productContextt from '@/product/context/product/productContext'
import CustomTimer from '@/custom/common/customTimer'
let serverTime = new Date()

const Grid = (props) => {
    const commonContext = useContext(CommonContext)
    const authContext = useContext(AuthContext)
    const productContext = useContext(productContextt)

    const { t } = useTranslation()
    const { configVariables, alldata_all_dropdown } = commonContext
    const { setBidHistoryValue } = useContext(CommonContextt)
    const { search_allsimilar, getAllSimilarProducts, search_allbidhistory, getAllBidHistory } =
        productContext
    const { isAuthenticated, user } = authContext
    const { setAlert } = useContext(AlertContext)
    const [product, setProduct] = useState()
    let [viewProduct, setViewProduct] = useState([])
    const history = useHistory()
    const [copyState, setCopyState] = useState({
        value: window.location.host + '/productView/',
        copy: false,
    })
    const [anchorEl, setAnchorEl] = React.useState(null)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = (history, path) => {
        setAnchorEl(null)
    }
    const viewProductRef = useRef(viewProduct)
    const userRef = useRef(user)

    useEffect(() => {
        viewProductRef.current = viewProduct
        userRef.current = user
    })
    const handler = (message, type) => {
        messageHandler(
            message,
            viewProductRef.current,
            userRef.current,
            setAlert,
            setViewProduct,
            type,
        )
    }

    useEffect(() => {
        if (socket) {
            socket.on('servertime', (data) => {
                if (moment(data.dTime).isValid()) {
                    serverTime = new Date(data.dTime)
                }
            })
            socket.on('realclosedupdates', (data) => {
                handler(data, 'realclosedupdates')
            })
            socket.on('bidAddtime', (data) => {
                handler(data, 'bidAddtime')
            })
        }
    }, [socket])

    useEffect(() => {
        let data = { ...props.data }
        if (data) {
            alldata_all_dropdown.map((e) => {
                if (e.id == data.conditiontypeid) {
                    data.condition = e.description
                }
                if (e.id == data.category) {
                    data.category = e.description
                }
            })
        }
        setViewProduct(data)
    }, [props.data])

    useEffect(() => {
        setProduct(viewProduct)
    }, [viewProduct])

    const getStarRatingIcons = (star) => {
        let starIcons = []
        for (let i = 1; i <= 5; i++) {
            if (i <= star) {
                starIcons.push(<span className="material-icons starRating">star_rate</span>)
            }
            if (i - parseFloat(star) == 0.5) {
                starIcons.push(<span className="material-icons starRating">star_half</span>)
            } else {
                if (i > star) {
                    starIcons.push(
                        <span className="material-icons-outlined starRating">star_rate</span>,
                    )
                }
            }
        }

        return starIcons
    }

    // const [remainingTime, setRemainingTime] = useState(null)
    // const [startTime, setStartTime] = useState(null)

    // const getRemainingTime = () => {
    //     if (product?.date_closed) {
    //         const dateClosed = product?.date_closed
    //         const currentTime = moment(serverTime)
    //         const closingTime = moment(dateClosed)
    //         const duration = moment.duration(closingTime.diff(currentTime))
    //         const remainingTimeHours = Math.floor(duration.asHours())
    //         const remainingTimeMinutes = duration.minutes()

    //         const formattedRemainingTime =
    //             remainingTimeHours > 24
    //                 ? closingTime.format('ddd h:mm a')
    //                 : `${remainingTimeHours} ${t('hrs')} ${remainingTimeMinutes} ${t('mins')}`

    //         return formattedRemainingTime
    //     }
    // }
    const settingBidHistoryValue = (id) => {
        console.log(isAuthenticated)
        if (!isAuthenticated) {
            history.push('/login')
            return
        }
        setBidHistoryValue(id)
    }

    // const getStartTime = () => {
    //     if (product?.date_closed) {
    //         const startdate = product.date_added
    //         const currentTime = moment(serverTime)
    //         const startTime = moment(startdate)

    //         const duration = moment.duration(startTime.diff(currentTime))
    //         const startTimeHours = Math.floor(duration.asHours())
    //         const startTimeMinutes = duration.minutes()

    //         const formattedRemainingTime =
    //             startTimeHours > 24
    //                 ? startTime.format('ddd h:mm a')
    //                 : `${startTimeHours} ${t('hrs')} ${startTimeMinutes} ${t('mins')}`

    //         return formattedRemainingTime
    //     }
    // }

    // useEffect(() => {
    //     setStartTime(getStartTime)
    //     if (product?.date_added) {
    //         const startdate = product.date_added
    //         const currentTime = moment()
    //         const startTime = moment(startdate)

    //         const duration = moment.duration(startTime.diff(currentTime))
    //         const startTimeHours = Math.floor(duration.asHours())
    //         if (startTimeHours < 24 && startTimeHours >= 0) {
    //             setInterval(
    //                 () => {
    //                     setStartTime(getStartTime)
    //                 },
    //                 props.timeIntervel ? props.timeIntervel : 1000,
    //             )
    //         }
    //     }
    // }, [product?.date_added])

    // useEffect(() => {
    //     setRemainingTime(getRemainingTime)
    //     if (product?.date_closed) {
    //         const dateClosed = product?.date_closed
    //         const currentTime = moment()
    //         const closingTime = moment(dateClosed)

    //         const duration = moment.duration(closingTime.diff(currentTime))
    //         const remainingTimeHours = Math.floor(duration.asHours())
    //         if (remainingTimeHours < 24) {
    //             setInterval(
    //                 () => {
    //                     setRemainingTime(getRemainingTime)
    //                 },
    //                 props.timeIntervel ? props.timeIntervel : 1000,
    //             )
    //         }
    //     }
    // }, [product?.date_closed, localStorage.i18nextLng])

    const [showNotes, setShowNotes] = useState(false)

    function isValidURL(url) {
        var urlRegex = new RegExp(
            '^(https?:\\/\\/)?' + // Protocol
                '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
                '((\\d{1,3}\\.){3}\\d{1,3}))' +
                '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
                '(\\?[;&a-z\\d%_.~+=-]*)?' +
                '(\\#[-a-z\\d_]*)?$',
            'i',
        )

        return urlRegex.test(url)
    }

    return (
        <div className="productCardGrid">
            {product ? (
                <>
                    <h2
                        className="gridProdTitle"
                        onClick={() =>
                            handleRedirectInternal(history, `productView/${product?.id}`)
                        }
                    >
                        {localStorage.i18nextLng === 'for_arabic' && product.arabic_title
                            ? product.arabic_title
                            : product.title}
                    </h2>
                    <div className="gridProdMisc">
                        {isAuthenticated && (
                            <div className="pgBidStatus">
                                {product.bidtopstatus === 'outbid' && (
                                    <h4 className="outbid">{t('Outbid')}</h4>
                                )}
                                {product.bidtopstatus === 'winner' && (
                                    <h4 className="winning">{t('winning')}</h4>
                                )}
                                {product.bidtopstatus === 'won' && (
                                    <h4 className="winning">{t('won')}</h4>
                                )}
                                {product.bidtopstatus === 'lost' && (
                                    <h4 className="outbid">{t('lost')}</h4>
                                )}
                            </div>
                        )}
                        {product.notes_listing ? (
                            <Button className="notes" onClick={() => setShowNotes(!showNotes)}>
                                {t('Notes')}
                            </Button>
                        ) : null}
                        {product.market_status === 'open' ? (
                            <FavouriteCheckbox
                                watchlisted={product.watchlistid}
                                project_id={product.id}
                            />
                        ) : null}
                        <Button onClick={handleClick} className="share">
                            <span className="material-icons">share</span>
                        </Button>
                        <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                            className="pgShare"
                        >
                            <MenuItem>
                                <CopyToClipboard
                                    text={copyState.value + product.id}
                                    onCopy={() => {
                                        setCopyState({ ...copyState, copy: true })
                                        setAlert('Copied Successfully', 'success')
                                    }}
                                >
                                    <Button className="copyClipboard">
                                        <span className="material-icons">content_copy</span>
                                        {t('Copy Link')}
                                    </Button>
                                </CopyToClipboard>
                            </MenuItem>
                            <MenuItem>
                                <FacebookShareButton
                                    url={copyState.value + product.id}
                                    quote={product.title}
                                >
                                    <span className="fab fa-facebook"></span>
                                    {t('Facebook')}
                                </FacebookShareButton>
                            </MenuItem>
                        </Menu>
                    </div>

                    <div className="pcgImg">
                        <div className={`notesCnt ${showNotes ? 'show' : ''}`}>
                            <p>{product.notes_listing}</p>
                        </div>
                        <div className="pcgPoints">
                            {product.deposit && product.deposit_amount ? product.deposit_amount : 0}{' '}
                            {t('Points')}
                        </div>
                        {/* <img
                            className="curserPointer"
                            src={isValidURL(product.avatar) ? product.avatar : product.avatarorg}
                            onClick={() =>
                                handleRedirectInternal(history, `productView/${product?.id}`)
                            }
                            alt={product.leadDescription}
                        /> */}
                        <img
                            className="curserPointer"
                            src={
                                isValidURL(product.avatar)
                                    ? product.avatar
                                    : isValidURL(product.avatarorg)
                                    ? product.avatarorg
                                    : '/assets/images/noimage.png'
                            }
                            onClick={() =>
                                handleRedirectInternal(history, `productView/${product?.id}`)
                            }
                            alt={product.leadDescription || 'No Image Available'}
                        />
                    </div>

                    <div className="productGridDetails">
                        {product.market_status === 'open' ? (
                            <>
                                <div className="pgTimePrice">
                                    {/* {product.future_active ? (
                                        <div>
                                            <h6>{t('starts_in')}:</h6>
                                            <p>{startTime}</p>
                                        </div>
                                    ) : (
                                        <div>
                                            <h6>{t('time_left')}:</h6>
                                            <p>{remainingTime}</p>
                                        </div>
                                    )} */}
                                    <div>
                                        {/* <h6>{t('time_left')}:</h6> */}
                                        {/* <p>{remainingTime}</p> */}
                                        <CustomTimer
                                            startText={t('starts_in')}
                                            endText={t('time_left')}
                                            withText={1}
                                            date_added={product.date_added}
                                            date_closed={product.date_closed}
                                        />
                                    </div>
                                    {product.buynow ? (
                                        <div>
                                            <h6>{`${t('buynow_price')}`}</h6>
                                            <p>{currencyFormat(product.bprice, t)}</p>
                                        </div>
                                    ) : (
                                        <div>
                                            <h6>{`${t(product.cbidtext)}`}</h6>
                                            <p>{currencyFormat(product.wprice, t)}</p>
                                        </div>
                                    )}
                                </div>
                                <div className="pgMiscInfoCnt">
                                    <div className="pgMiscInfo">
                                        <div>
                                            <h6>{t('retail')}</h6>
                                            <p>
                                                {currencyFormat(
                                                    product.retail_price ? product.retail_price : 0,
                                                    t,
                                                )}
                                            </p>
                                        </div>
                                        <div>
                                            <h6>{t('condition')}</h6>
                                            <p>{t(product.condition)}</p>
                                        </div>
                                        <div>
                                            <h6>{t('bids')}</h6>
                                            <p
                                                className="cursorDecoy"
                                                onClick={() => {
                                                    settingBidHistoryValue(product.id)
                                                }}
                                            >
                                                {product.auction
                                                    ? product.bid_count > product.bidCount
                                                        ? product.bid_count
                                                        : product.bidCount
                                                    : '-'}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="pgRating">
                                        {Array.from({ length: 5 }, (_, index) => {
                                            if (index + 1 <= product.rating)
                                                return <span className="material-icons">star</span>
                                            if (
                                                index + 1 - product.rating > 0 &&
                                                index + 1 - product.rating < 1
                                            )
                                                return (
                                                    <span className="material-icons">
                                                        star_half
                                                    </span>
                                                )
                                            else
                                                return (
                                                    <span className="material-icons">
                                                        star_outline
                                                    </span>
                                                )
                                        })}
                                        {/* <span className="material-icons">star</span>
                                        <span className="material-icons">star</span>
                                        <span className="material-icons">star_half</span>
                                        <span className="material-icons">star_outline</span> */}
                                    </div>
                                    <div className="gridBidBox">
                                        {!product.future_active ? (
                                            product.auction && product.buynow ? (
                                                <>
                                                    {product.custom_auctionlot_bid_type ===
                                                    'proxy' ? (
                                                        <Bidding data={product} type={'proxy'} />
                                                    ) : (
                                                        <Bidding data={product} type={'hard'} />
                                                    )}
                                                    <Buynow
                                                        data={product}
                                                        editQuantity={true}
                                                        isOnlyBuynow={
                                                            product.auction === 0 ? true : false
                                                        }
                                                        className={'mt-2'}
                                                    />
                                                </>
                                            ) : product.auction ? (
                                                <>
                                                    {product.custom_auctionlot_bid_type ===
                                                    'proxy' ? (
                                                        <Bidding data={product} type={'proxy'} />
                                                    ) : (
                                                        <Bidding data={product} type={'hard'} />
                                                    )}
                                                </>
                                            ) : (
                                                <Buynow
                                                    data={product}
                                                    editQuantity={true}
                                                    isOnlyBuynow={
                                                        product.auction === 0 ? true : false
                                                    }
                                                />
                                            )
                                        ) : null}
                                    </div>
                                </div>
                            </>
                        ) : product.market_status === 'closed' ? (
                            <>
                                <div className="pgTimePrice">
                                    <div>
                                        <h6>{t('closed_on')}:</h6>
                                        <p>{dateFormatFront(product.date_closed)}</p>
                                    </div>
                                    <div>
                                        <h6>{`${t(product.cbidtext)}`}</h6>
                                        <p>{currencyFormat(product.wprice, t)}</p>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="pgTimePrice">
                                    <div>
                                        <h6>{t('closed_on')}:</h6>
                                        <p>{dateFormatFront(product.date_closed)}</p>
                                    </div>
                                    <div>
                                        <h6>{`${t(product.cbidtext)}`}</h6>
                                        <p>{currencyFormat(product.wprice, t)}</p>
                                    </div>
                                </div>
                                <div className="pgMiscInfoCnt">
                                    <div className="pgMiscInfo">
                                        <div>
                                            <h6>{t('retail')}</h6>
                                            <p>
                                                {currencyFormat(
                                                    product.retail_price ? product.retail_price : 0,
                                                    t,
                                                )}
                                            </p>
                                        </div>
                                        <div>
                                            <h6>{t('bids')}</h6>
                                            <p
                                                className="cursorDecoy"
                                                onClick={() => setBidHistoryValue(product.id)}
                                            >
                                                {product.auction
                                                    ? product.bid_count > product.bidCount
                                                        ? product.bid_count
                                                        : product.bidCount
                                                    : '-'}
                                            </p>
                                        </div>
                                        <div>
                                            <h6>{t('buyers_premium')}</h6>
                                            <p>
                                                {configVariables?.buyer_premium
                                                    ? configVariables.buyer_premium
                                                    : product?.buyer_premium}
                                                %
                                            </p>
                                        </div>
                                    </div>
                                    <div className="pgRating">
                                        <span className="material-icons">star</span>
                                        <span className="material-icons">star</span>
                                        <span className="material-icons">star</span>
                                        <span className="material-icons">star_half</span>
                                        <span className="material-icons">star_outline</span>
                                    </div>
                                </div>

                                {product.bidtopstatus === 'won' && (
                                    <div className="actionAfterWin">
                                        {product.appointmentId === null &&
                                        product.buynow_status === 'active' &&
                                        product.market_status !== 'relisted' ? (
                                            <>
                                                <PrimaryButton
                                                    label={t('make_payment')}
                                                    btnSize="small"
                                                    onClick={() =>
                                                        handleRedirectInternal(history, 'cart')
                                                    }
                                                />
                                            </>
                                        ) : null}
                                    </div>
                                )}
                            </>
                        )}
                        {product.bidtopstatus === 'won' ? (
                            <div className="listLotDetails statusMessage">
                                {
                                    product.appointmentId === null ? (
                                        product.buynow_status === 'canceled' ? (
                                            <>
                                                <span className="material-icons">cancel</span>
                                                <h6> {t('Returned / Canceled')}</h6>
                                            </>
                                        ) : product.market_status === 'relisted' ? (
                                            <>
                                                <span className="material-icons">event_busy</span>
                                                <h6> {t('Item Expired')}</h6>
                                            </>
                                        ) : (
                                            <>
                                                <span className="material-icons">announcement</span>
                                                <h6> {t('Needs Appointment')}</h6>
                                            </>
                                        )
                                    ) : product.appointmentStatusTypeId == '2' ||
                                      product.appointmentStatusTypeId == '3' ? (
                                        <>
                                            <span className="material-icons">priority_high</span>
                                            <h6> {t('Ready for Pickup')}</h6>
                                        </>
                                    ) : product.appointmentStatusTypeId == '4' ? (
                                        <>
                                            <span className="material-icons">check_circle</span>
                                            <h6 style={{ display: 'inlineBlock' }}>
                                                {' '}
                                                {t('Picked Up')}
                                            </h6>
                                        </>
                                    ) : product.appointmentStatusTypeId == '5' ? (
                                        <>
                                            <span className="material-icons">event_busy</span>
                                            <h6> {t('canceled')}</h6>
                                        </>
                                    ) : null
                                    // (
                                    //     <>
                                    //         <span className="material-icons">schedule</span>
                                    //         <h6> {t('Appointment Scheduled')}</h6>
                                    //     </>
                                    // )
                                }
                            </div>
                        ) : null}
                    </div>
                </>
            ) : null}
        </div>
    )
}

export default Grid
