import React, { useState, useContext, useEffect } from 'react'
import { Button, IconButton, ListItem } from '@material-ui/core'
import { NavLink } from 'react-router-dom'
import DashboardLayout from './DashboardLayout'
import PrimaryButton from '@/product/components/atoms/PrimaryButton'
import SecondaryButton from '@/product/components/atoms/SecondaryButton'
import { mapData } from '@/product/common/components'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import authContext from '@/product/context/auth/authContext'
import HyperPayCardContext from '@/product/context/payment/hyperpay/card/cardContext'
import HyperCard from './HyperCard'
import './Dashboard.css'
import Loaders from '@/custom/components/molecules/Loaders'
import AddCard from './PaymentSuccess'

const MyHyperCard = (props) => {
    const [cards, setCards] = useState([])
    const { user, loadUser, isAuthenticated } = useContext(authContext)
    const [checkoutId, setCheckoutId] = useState(0)
    const [isLoading, setIsLoading] = useState(false)

    const {
        getAllHyperPayCards,
        get_all_card_details,
        responseStatus: responseStatusCard,
        clearResponse: clearResponseCard,
        createCard,
        deleteCard,
        updateCard,
    } = useContext(HyperPayCardContext)

    const [manageCard, setManageCard] = useState({
        popup: false,
        status: 'new',
        data: null,
    })
    const toggleFullScreenCardPopup = (popup, status, data) => {
        setManageCard({ popup, status, data })
    }

    const { t } = useTranslation()
    //const [addCard, setAddCard] = useState(false)
    /*const addCardData = [
        {
            label: t('name'),
            name: 'name',
            type: 'email',
            placeholder: t('enter_card_holder_name'),
            class: 'col-12',
            autoFocus: true,
            formik: formik,
        },
        {
            label: t('card_number'),
            name: 'card_number',
            type: 'number',
            placeholder: t('enter_card_number'),
            class: 'col-12',
            formik: formik,
        },
        {
            label: t('name'),
            name: 'name',
            type: 'email',
            placeholder: t('enter_card_holder_name'),
            class: 'col-12',
            autoFocus: true,
            formik: formik,
        },
        {
            label: 'CVV',
            name: 'cvv',
            type: 'text',
            placeholder: t('enter') + ' CVV',
            class: 'col-12',
            formik: formik,
        },
        {
            label: t('expiry_date'),
            name: 'expiry_date',
            type: 'date',
            placeholder: t('date_of_card_expiration'),
            class: 'col-12',
            formik: formik,
        },
    ]*/

    useEffect(() => {
        if (isAuthenticated) {
            if (user) {
                if (user.id) {
                    getAllHyperPayCards()
                }
            }
        }
    }, [user, isAuthenticated])

    const shopperResultUrl = (data) => {
        console.log(data, 'coming data')
    }

    useEffect(() => {
        if (responseStatusCard) {
            if (responseStatusCard.from === 'card_source_create') {
                if (responseStatusCard.status === 'success') {
                    console.log(responseStatusCard.data.id, 'repooo')
                    setCheckoutId(responseStatusCard.data.id)
                    toggleFullScreenCardPopup(true, 'new', null)
                    setIsLoading(false)
                }
            } else if (
                responseStatusCard.from === 'card_source_update' ||
                responseStatusCard.from === 'card_source_delete'
            ) {
                getAllHyperPayCards()
            }
            clearResponseCard()
            // setIsLoading(false)
        }
    }, [responseStatusCard])

    useEffect(() => {
        setCheckoutId(0)
        toggleFullScreenCardPopup(true, 'new', null)
    }, [])

    useEffect(() => {
        if (get_all_card_details) {
            console.log('!@#get_all_card_details', get_all_card_details)
            setCards(get_all_card_details.records)
        }
        return () => {
            setCards([])
        }
    }, [get_all_card_details])

    const addCardFunc = () => {
        createCard()
        setIsLoading(true)
    }

    return (
        <DashboardLayout title={'CARD DETAILS'}>
            {isLoading ? (
                <Loaders name="home" isLoading={isLoading} />
            ) : (
                <>
                    <div className="dashboardInner mySavedCards">
                        <div
                            className="emptySavedCard"
                            // onClick={() => toggleFullScreenCardPopup(true, 'new', null)}
                            onClick={() => addCardFunc()}
                        >
                            <img src="/assets/images/cards.png" alt="bank" className="mt-1 mb-1" />
                            <h6>{t('Add card')}</h6>
                            <img src="/assets/images/ccard.png" alt="bank" className="mt-1 mb-1" />
                        </div>
                        {cards.length > 0
                            ? cards.map((card) => {
                                  return (
                                      // eslint-disable-next-line react/jsx-key
                                      <div
                                          className={`saved-card ${
                                              card && card.is_default ? 'default' : ''
                                          }`}
                                      >
                                          <div className="filledSavedCard">
                                              {card && card.is_default ? (
                                                  <p className="defaultCard">{t('Default')}</p>
                                              ) : (
                                                  ''
                                              )}

                                              <div className="row mt-2">
                                                  <div className="col-6 text-left">
                                                      <img
                                                          src="/assets/images/simn.png"
                                                          alt="bank"
                                                      />
                                                  </div>
                                                  {/* <div className="sc-primary-label">Primary Card</div> */}
                                              </div>
                                              <div className="card-num">
                                                  <span>{card.bin}</span>
                                                  <span>****</span>
                                                  <span>****</span>
                                                  <span>{card.last4Digits}</span>
                                              </div>
                                              {/* {console.log('!@#card', card)} */}
                                              <div className="clearfix">
                                                  <div className="float-left">
                                                      <div className="fs-12">
                                                          {card?.issuer?.bank}
                                                      </div>
                                                  </div>
                                                  <div className="float-right">
                                                      <div className="fs-12">
                                                          {card.expiryMonth} / {card.expiryYear}
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                          <div className="sc-btns-wrap d-flex align-items-center justify-content-between">
                                              {/* <IconButton
                                          className="edtBtn"
                                          onClick={() =>
                                              toggleFullScreenCardPopup(true, 'update', card)
                                          }
                                      >
                                          <span className="material-icons">edit</span>
                                      </IconButton> */}
                                              {cards.length > 1 && (
                                                  <>
                                                      {card && card.id !== card.default_source && (
                                                          <>
                                                              <IconButton
                                                                  className="defaultBtn"
                                                                  onClick={() =>
                                                                      toggleFullScreenCardPopup(
                                                                          true,
                                                                          'default',
                                                                          card,
                                                                      )
                                                                  }
                                                              >
                                                                  <span className="material-icons">
                                                                      check_circle
                                                                  </span>
                                                              </IconButton>

                                                              <IconButton
                                                                  className="dltBtn"
                                                                  onClick={() =>
                                                                      toggleFullScreenCardPopup(
                                                                          true,
                                                                          'delete',
                                                                          card,
                                                                      )
                                                                  }
                                                              >
                                                                  <span className="material-icons">
                                                                      delete
                                                                  </span>
                                                              </IconButton>
                                                          </>
                                                      )}
                                                  </>
                                              )}
                                          </div>
                                      </div>
                                  )
                              })
                            : ''}
                        <HyperCard
                            scriptSrc={checkoutId}
                            shopperResultUrl={shopperResultUrl}
                            data={manageCard}
                            allCards={cards}
                            function={toggleFullScreenCardPopup}
                        />

                        {/*<Popup
                    open={addCard}
                    size="md"
                    handleClose={() => setAddCard(false)}
                    modaltitle={t('add_a_new_card')}
                >
                    <form onSubmit={formik.handleSubmit}>
                        <div className="row">{Object.values(mapData(addCardData))}</div>
                        <div className="row">
                            <div className="col-12 text-center">
                                <PrimaryButton label={t('save')} />
                            </div>
                        </div>
                    </form>
                </Popup>*/}
                    </div>
                </>
            )}
        </DashboardLayout>
    )
}

export default MyHyperCard
