export const messageHandler = (
    data,
    product,
    user,
    setAlert,
    setViewProduct,
    type,
    bidHistoryChange,
    customMessage,
) => {
    console.log('i am socket handle ', data, type)
    let user_id = user ? user.id : 0
    const index = product.findIndex((s) => s.id === parseInt(data.id, 10))

    let productToChange = product[index]
    if (index !== -1) {
        if (type === 'realclosedupdates') {
            if (data.usr !== '') {
                if (user_id === parseInt(data.bpop_cbidder)) {
                    product[index] = {
                        ...productToChange,
                        market_status: 'sold',
                        bidtopstatus: 'won',
                        buynowamount: data.buynowamount,
                        cbidtext: 'Sold Price',
                        buynowed: data.bpop_cbidder,
                        buynowpaid: 0,
                        common_invoice: data.common_invoice,
                    }
                    setViewProduct([...product])
                } else if (parseInt(productToChange.bid_or_not) > 0) {
                    product[index] = {
                        ...productToChange,
                        market_status: 'sold',
                        bidtopstatus: 'lost',
                        buynowamount: data.buynowamount,
                        cbidtext: 'Sold Price',
                        buynowed: data.bpop_cbidder,
                        buynowpaid: 0,
                    }
                    setViewProduct([...product])
                } else {
                    product[index] = {
                        ...productToChange,
                        market_status: 'sold',
                        buynowamount: data.buynowamount,
                        cbidtext: 'Sold Price',
                    }
                    setViewProduct([...product])
                }
            } else {
                if (parseInt(productToChange.bid_or_not) > 0) {
                    product[index] = {
                        ...productToChange,
                        market_status: 'closed',
                        bidtopstatus: 'lost',
                    }
                    setViewProduct([...product])
                } else {
                    product[index] = {
                        ...productToChange,
                        market_status: 'closed',
                    }
                    setViewProduct([...product])
                }
            }
        } else if (type === 'customBidData') {
            if (data.status !== 'failed') {
                if (user_id === parseInt(data.bpop_cbidder)) {
                    product[index] = {
                        ...productToChange,
                        custom_bid_data: data?.customBidData?.length
                            ? [{ ...product[index]?.custom_bid_data[0], ...data?.customBidData[0] }]
                            : product[index]?.custom_bid_data,
                    }
                    console.log('i am setProduct', [...product])
                    setViewProduct([...product])
                }
            }
        } else if (type === 'bidAddtime') {
            let date_closed = product[index].date_closed
            if (parseInt(data.bpop_belowFive) === 1) {
                date_closed = data.bpop_belowFiveIncrement
            }
            if (data.status === 'failed' && user_id === parseInt(data.bpop_cbidder)) {
                setAlert(data.error, 'error')
            }
            if (data.status !== 'failed') {
                productToChange.reserve_met = data.reserve_met || false
                if (data.bidding_type === 'sealed') {
                    if (user_id === parseInt(data.bpop_cbidder)) {
                        let rank = 0
                        if (
                            product[index].sale_type &&
                            product[index].sale_type === 'sealed_rank'
                        ) {
                            if (data.bidhistory) {
                                const filteredData = [
                                    ...new Set(
                                        data.bidhistory.map((bidAll) => bidAll.user_id.toString()),
                                    ),
                                ]
                                const rankDetails = filteredData.indexOf(user_id.toString())

                                if (rank !== -1) {
                                    rank = rankDetails + 1
                                }
                            }
                        }

                        setAlert(
                            customMessage?.sealedSuccess || 'Bid placed successfully',
                            'success',
                        )

                        product[index] = {
                            ...productToChange,
                            next_bid: data.bpop_cuser_nextbid,
                            wprice: data.bpop_wprice,
                            bidtopstatus: data.bpop_bidstatus,
                            bid_count: data.bpop_bidcount,
                            bid_or_not: 1,
                            date_closed,
                            cbidtext: 'Current Price',
                            current_bid: data.cuser_enteramount,
                            rank,
                        }
                        setViewProduct([...product])
                    } else {
                        if (parseInt(productToChange.bid_or_not) > 0) {
                            let rank = 0
                            if (
                                product[index].sale_type &&
                                product[index].sale_type === 'sealed_rank'
                            ) {
                                if (data.bidhistory) {
                                    const filteredData = [
                                        ...new Set(
                                            data.bidhistory.map((bidAll) =>
                                                bidAll.user_id.toString(),
                                            ),
                                        ),
                                    ]
                                    const rankDetails = filteredData.indexOf(user_id.toString())

                                    if (rank !== -1) {
                                        rank = rankDetails + 1
                                    }
                                }
                            }

                            if (user_id === parseInt(data.bpop_higher)) {
                                if (
                                    parseInt(productToChange.next_bid) < parseInt(data.bpop_nextbid)
                                ) {
                                    product[index] = {
                                        ...productToChange,
                                        wprice: data.bpop_wprice,
                                        bidtopstatus: 'winner',
                                        bid_count: data.bpop_bidcount,
                                        date_closed,
                                        rank,
                                    }
                                    setViewProduct([...product])
                                } else {
                                    product[index] = {
                                        ...productToChange,
                                        wprice: data.bpop_wprice,
                                        bidtopstatus: 'winner',
                                        bid_count: data.bpop_bidcount,
                                        date_closed,
                                        rank,
                                    }
                                    setViewProduct([...product])
                                }
                            } else {
                                product[index] = {
                                    ...productToChange,
                                    wprice: data.bpop_wprice,
                                    bid_count: data.bpop_bidcount,
                                    bidtopstatus: 'outbid',
                                    bid_or_not: 1,
                                    date_closed,
                                    rank,
                                }
                                setViewProduct([...product])
                            }
                        } else {
                            product[index] = {
                                ...productToChange,
                                wprice: data.bpop_wprice,
                                bid_count: data.bpop_bidcount,
                                date_closed,
                            }
                            setViewProduct([...product])
                        }
                    }
                } else {
                    if (user_id === parseInt(data.bpop_cbidder)) {
                        let rank = 0
                        if (data.auction_type && data.auction_type === 'reverse') {
                            if (data.bidhistory) {
                                const filteredData = [
                                    ...new Set(
                                        data.bidhistory.map((bidAll) => bidAll.user_id.toString()),
                                    ),
                                ]
                                const rankDetails = filteredData.indexOf(user_id.toString())

                                if (rank !== -1) {
                                    rank = rankDetails + 1
                                }
                            }
                        }

                        setAlert(customMessage?.bidSuccess || 'Bid placed successfully', 'success')
                        if (typeof product[index]?.proxy_bid_details === 'undefined') {
                            product[index].proxy_bid_details = {}
                        }
                        if (data.bpop_watchListAdded) {
                            productToChange.watchlistid = 1
                        }
                        product[index] = {
                            ...productToChange,
                            next_bid: data.bpop_cuser_nextbid,
                            wprice: data.bpop_wprice,
                            bidtopstatus: data.bpop_bidstatus,
                            bid_count: data.bpop_bidcount,
                            biddercurrentbid: data.cuser_enteramount,
                            incrementamt: data.bpop_increment,
                            bidderproxyant: data.bpop_bidderproxyant,
                            bid_or_not: 1,
                            date_closed,
                            proxy_bid_details: {
                                ...product[index]?.proxy_bid_details,
                                maxamount:
                                    data.bpop_bidstatus === 'winner'
                                        ? data.bpop_wprice_morehigh
                                        : product[index]?.proxy_bid_details.maxamount,
                                user_id:
                                    data.bpop_bidstatus === 'winner'
                                        ? data.bpop_cbidder
                                        : product[index]?.proxy_bid_details.bpop_cbidder,
                            },
                            cbidtext: 'Current Price',
                            rank,
                            userLocationAdded: true,
                            custom_bid_data: data?.customBidData?.length
                                ? [...product[index]?.custom_bid_data, data?.customBidData[0] || {}]
                                : product[index]?.custom_bid_data,
                        }
                        console.log('i am setProduct', [...product])
                        setViewProduct([...product])
                    } else {
                        if (parseInt(productToChange.bid_or_not) > 0) {
                            let rank = 0
                            if (data.auction_type && data.auction_type === 'reverse') {
                                if (data.bidhistory) {
                                    const filteredData = [
                                        ...new Set(
                                            data.bidhistory.map((bidAll) =>
                                                bidAll.user_id.toString(),
                                            ),
                                        ),
                                    ]
                                    const rankDetails = filteredData.indexOf(user_id.toString())

                                    if (rank !== -1) {
                                        rank = rankDetails + 1
                                    }
                                }
                            }
                            if (data.bpop_watchListAdded) {
                                productToChange.watchlistid = 1
                            }

                            if (user_id === parseInt(data.bpop_higher)) {
                                if (data.auction_type && data.auction_type === 'reverse') {
                                    if (
                                        parseInt(productToChange.next_bid) >
                                        parseInt(data.bpop_nextbid)
                                    ) {
                                        product[index] = {
                                            ...productToChange,
                                            next_bid: data.bpop_nextbid_org,
                                            wprice: data.bpop_wprice,
                                            bidtopstatus: 'winner',
                                            bid_count: data.bpop_bidcount,
                                            date_closed,
                                            rank,
                                        }
                                        setViewProduct([...product])
                                    } else {
                                        product[index] = {
                                            ...productToChange,
                                            wprice: data.bpop_wprice,
                                            bidtopstatus: 'winner',
                                            bid_count: data.bpop_bidcount,
                                            date_closed,
                                            rank,
                                        }
                                        setViewProduct([...product])
                                    }
                                } else {
                                    if (
                                        parseInt(productToChange.next_bid) <
                                        parseInt(data.bpop_nextbid)
                                    ) {
                                        product[index] = {
                                            ...productToChange,
                                            next_bid: data.bpop_nextbid_org,
                                            incrementamt: data.bpop_increment,
                                            wprice: data.bpop_wprice,
                                            bidtopstatus: 'winner',
                                            bid_count: data.bpop_bidcount,
                                            date_closed,
                                        }
                                        setViewProduct([...product])
                                    } else {
                                        product[index] = {
                                            ...productToChange,
                                            wprice: data.bpop_wprice,
                                            incrementamt: data.bpop_increment,
                                            bidtopstatus: 'winner',
                                            bid_count: data.bpop_bidcount,
                                            date_closed,
                                        }
                                        setViewProduct([...product])
                                    }
                                }
                            } else {
                                product[index] = {
                                    ...productToChange,
                                    next_bid: data.bpop_nextbid_org,
                                    wprice: data.bpop_wprice,
                                    incrementamt: data.bpop_increment,
                                    bid_count: data.bpop_bidcount,
                                    bidtopstatus: 'outbid',
                                    bid_or_not: 1,
                                    date_closed,
                                    rank,
                                }
                                setViewProduct([...product])
                            }
                        } else {
                            if (data.bpop_watchListAdded) {
                                productToChange.watchlistid = 1
                            }
                            product[index] = {
                                ...productToChange,
                                next_bid: data.bpop_nextbid_org,
                                wprice: data.bpop_wprice,
                                bid_count: data.bpop_bidcount,
                                incrementamt: data.bpop_increment,
                                cbidtext: 'Current Price',
                                date_closed,
                            }
                            setViewProduct([...product])
                        }
                    }
                }
                if (bidHistoryChange) {
                    bidHistoryChange({ product_id: productToChange.id })
                }
                // getBidHistoryProduct({ product_id: project_id })
            }
        } else if (type === 'preBidAddtime') {
            if (data.status === 'failed' && user_id === parseInt(data.bpop_cbidder)) {
                setAlert(data.error, 'error')
            }
            if (data.status !== 'failed') {
                if (user_id === parseInt(data.bpop_cbidder)) {
                    setAlert(
                        customMessage?.prebidSuccess || 'Pre Bid placed successfully',
                        'success',
                    )
                    product[index] = {
                        ...productToChange,
                        prebid_data: {
                            proposed_amount: data.cuser_enteramount,
                            proposal: data.bidding_type,
                        },
                    }
                    setViewProduct([...product])
                }
            }
        } else if (type === 'watchlistAdded') {
            product[index] = {
                ...productToChange,
                watchlistid: data.status === 'added' ? 1 : 0,
            }
            setViewProduct([...product])
        } else if (type === 'viewcount') {
            product[index] = {
                ...productToChange,
                product_view_counts: data.view_count,
            }
            setViewProduct([...product])
        }
    }
}

export const messageHandlerSingle = (
    data,
    product,
    user,
    setAlert,
    setViewProduct,
    type,
    showAlert,
    bidHistoryChange,
    customMessage,
) => {
    let user_id = user?.id
    const index = product?.id === parseInt(data?.id, 10)
    let productToChange = product
    if (index) {
        if (type === 'realclosedupdates') {
            if (data.usr !== '') {
                if (user_id === parseInt(data.bpop_cbidder)) {
                    product = {
                        ...productToChange,
                        market_status: 'sold',
                        bidtopstatus: 'won',
                        buynowamount: data.buynowamount,
                        cbidtext: 'Sold Price',
                        buynowed: data.bpop_cbidder,
                        buynowpaid: 0,
                        common_invoice: data.common_invoice,
                    }
                    setViewProduct(product)
                } else if (parseInt(productToChange.bid_or_not) > 0) {
                    product = {
                        ...productToChange,
                        market_status: 'sold',
                        bidtopstatus: 'lost',
                        buynowamount: data.buynowamount,
                        cbidtext: 'Sold Price',
                        buynowed: data.bpop_cbidder,
                        buynowpaid: 0,
                    }
                    setViewProduct(product)
                } else {
                    product = {
                        ...productToChange,
                        market_status: 'sold',
                        buynowamount: data.buynowamount,
                        cbidtext: 'Sold Price',
                    }
                    setViewProduct(product)
                }
            } else {
                if (parseInt(productToChange.bid_or_not) > 0) {
                    product = {
                        ...productToChange,
                        market_status: 'closed',
                        bidtopstatus: 'lost',
                    }
                    setViewProduct(product)
                } else {
                    product = {
                        ...productToChange,
                        market_status: 'closed',
                    }
                    setViewProduct(product)
                }
            }
        } else if (type === 'customBidData') {
            if (data.status !== 'failed') {
                if (user_id === parseInt(data.bpop_cbidder)) {
                    product = {
                        ...productToChange,
                        custom_bid_data: data?.customBidData?.length
                            ? [{ ...product[index]?.custom_bid_data[0], ...data?.customBidData[0] }]
                            : product[index]?.custom_bid_data,
                    }
                    console.log('i am setProduct', [...product])
                    setViewProduct(product)
                }
            }
        } else if (type === 'bidAddtime') {
            let date_closed = product.date_closed
            if (parseInt(data.bpop_belowFive) === 1) {
                date_closed = data.bpop_belowFiveIncrement
            }
            if (data.status === 'failed' && user_id === parseInt(data.bpop_cbidder)) {
                if (showAlert) {
                    setAlert(data.error, 'error')
                }
            }
            if (data.status !== 'failed') {
                productToChange.reserve_met = data.reserve_met || false
                if (data.bidding_type === 'sealed') {
                    if (user_id === parseInt(data.bpop_cbidder)) {
                        let rank = 0
                        if (
                            productToChange.sale_type &&
                            productToChange.sale_type === 'sealed_rank'
                        ) {
                            if (data.bidhistory) {
                                const filteredData = [
                                    ...new Set(
                                        data.bidhistory.map((bidAll) => bidAll.user_id.toString()),
                                    ),
                                ]
                                const rankDetails = filteredData.indexOf(user_id.toString())

                                if (rank !== -1) {
                                    rank = rankDetails + 1
                                }
                            }
                        }

                        if (showAlert) {
                            setAlert(
                                customMessage?.sealedSuccess || 'Bid placed successfully',
                                'success',
                            )
                        }

                        product = {
                            ...productToChange,
                            next_bid: data.bpop_cuser_nextbid,
                            wprice: data.bpop_wprice,
                            bidtopstatus: data.bpop_bidstatus,
                            bid_count: data.bpop_bidcount,
                            bid_or_not: 1,
                            date_closed,
                            cbidtext: 'Current Price',
                            current_bid: data.cuser_enteramount,
                            rank,
                        }
                        setViewProduct(product)
                    } else {
                        if (parseInt(productToChange.bid_or_not) > 0) {
                            let rank = 0
                            if (
                                productToChange.sale_type &&
                                productToChange.sale_type === 'sealed_rank'
                            ) {
                                if (data.bidhistory) {
                                    const filteredData = [
                                        ...new Set(
                                            data.bidhistory.map((bidAll) =>
                                                bidAll.user_id.toString(),
                                            ),
                                        ),
                                    ]
                                    const rankDetails = filteredData.indexOf(user_id.toString())

                                    if (rank !== -1) {
                                        rank = rankDetails + 1
                                    }
                                }
                            }

                            if (user_id === parseInt(data.bpop_higher)) {
                                if (
                                    parseInt(productToChange.next_bid) < parseInt(data.bpop_nextbid)
                                ) {
                                    product = {
                                        ...productToChange,
                                        wprice: data.bpop_wprice,
                                        bidtopstatus: 'winner',
                                        bid_count: data.bpop_bidcount,
                                        date_closed,
                                        rank,
                                    }
                                    setViewProduct(product)
                                } else {
                                    product = {
                                        ...productToChange,
                                        wprice: data.bpop_wprice,
                                        bidtopstatus: 'winner',
                                        bid_count: data.bpop_bidcount,
                                        date_closed,
                                        rank,
                                    }
                                    setViewProduct(product)
                                }
                            } else {
                                product = {
                                    ...productToChange,
                                    wprice: data.bpop_wprice,
                                    bid_count: data.bpop_bidcount,
                                    bidtopstatus: 'outbid',
                                    bid_or_not: 1,
                                    date_closed,
                                    rank,
                                }
                                setViewProduct(product)
                            }
                        } else {
                            product = {
                                ...productToChange,
                                wprice: data.bpop_wprice,
                                bid_count: data.bpop_bidcount,
                                date_closed,
                            }
                            setViewProduct(product)
                        }
                    }
                } else {
                    if (user_id === parseInt(data.bpop_cbidder)) {
                        let rank = 0
                        if (data.auction_type && data.auction_type === 'reverse') {
                            if (data.bidhistory) {
                                const filteredData = [
                                    ...new Set(
                                        data.bidhistory.map((bidAll) => bidAll.user_id.toString()),
                                    ),
                                ]
                                const rankDetails = filteredData.indexOf(user_id.toString())

                                if (rank !== -1) {
                                    rank = rankDetails + 1
                                }
                            }
                        }
                        if (showAlert) {
                            setAlert(
                                customMessage?.bidSuccess || 'Bid placed successfully',
                                'success',
                            )
                        }
                        if (typeof product?.proxy_bid_details === 'undefined') {
                            product.proxy_bid_details = {}
                        }
                        if (data.bpop_watchListAdded) {
                            productToChange.watchlistid = 1
                        }
                        product = {
                            ...productToChange,
                            next_bid: data.bpop_cuser_nextbid,
                            wprice: data.bpop_wprice,
                            bidtopstatus: data.bpop_bidstatus,
                            bid_count: data.bpop_bidcount,
                            biddercurrentbid: data.cuser_enteramount,
                            incrementamt: data.bpop_increment,
                            bidderproxyant: data.bpop_bidderproxyant,
                            bid_or_not: 1,
                            date_closed,
                            proxy_bid_details: {
                                ...product?.proxy_bid_details,
                                maxamount:
                                    data.bpop_bidstatus === 'winner'
                                        ? data.bpop_wprice_morehigh
                                        : product?.proxy_bid_details.maxamount,
                                user_id:
                                    data.bpop_bidstatus === 'winner'
                                        ? data.bpop_cbidder
                                        : product?.proxy_bid_details.bpop_cbidder,
                            },
                            cbidtext: 'Current Price',
                            rank,
                            userLocationAdded: true,
                            custom_bid_data: data?.customBidData?.length
                                ? [...product?.custom_bid_data, data?.customBidData[0] || {}]
                                : product?.custom_bid_data,
                        }
                        setViewProduct(product)
                    } else {
                        if (parseInt(productToChange.bid_or_not) > 0) {
                            let rank = 0
                            if (data.auction_type && data.auction_type === 'reverse') {
                                if (data.bidhistory) {
                                    const filteredData = [
                                        ...new Set(
                                            data.bidhistory.map((bidAll) =>
                                                bidAll.user_id.toString(),
                                            ),
                                        ),
                                    ]
                                    const rankDetails = filteredData.indexOf(user_id.toString())

                                    if (rank !== -1) {
                                        rank = rankDetails + 1
                                    }
                                }
                            }
                            if (data.bpop_watchListAdded) {
                                productToChange.watchlistid = 1
                            }

                            if (user_id === parseInt(data.bpop_higher)) {
                                if (data.auction_type && data.auction_type === 'reverse') {
                                    if (
                                        parseInt(productToChange.next_bid) >
                                        parseInt(data.bpop_nextbid)
                                    ) {
                                        product = {
                                            ...productToChange,
                                            next_bid: data.bpop_nextbid_org,
                                            wprice: data.bpop_wprice,
                                            bidtopstatus: 'winner',
                                            bid_count: data.bpop_bidcount,
                                            date_closed,
                                            rank,
                                        }
                                        setViewProduct(product)
                                    } else {
                                        product = {
                                            ...productToChange,
                                            wprice: data.bpop_wprice,
                                            bidtopstatus: 'winner',
                                            bid_count: data.bpop_bidcount,
                                            date_closed,
                                            rank,
                                        }
                                        setViewProduct(product)
                                    }
                                } else {
                                    if (
                                        parseInt(productToChange.next_bid) <
                                        parseInt(data.bpop_nextbid)
                                    ) {
                                        product = {
                                            ...productToChange,
                                            next_bid: data.bpop_nextbid_org,
                                            incrementamt: data.bpop_increment,
                                            wprice: data.bpop_wprice,
                                            bidtopstatus: 'winner',
                                            bid_count: data.bpop_bidcount,
                                            date_closed,
                                        }
                                        setViewProduct(product)
                                    } else {
                                        product = {
                                            ...productToChange,
                                            wprice: data.bpop_wprice,
                                            incrementamt: data.bpop_increment,
                                            bidtopstatus: 'winner',
                                            bid_count: data.bpop_bidcount,
                                            date_closed,
                                        }
                                        setViewProduct(product)
                                    }
                                }
                            } else {
                                product = {
                                    ...productToChange,
                                    next_bid: data.bpop_nextbid_org,
                                    wprice: data.bpop_wprice,
                                    incrementamt: data.bpop_increment,
                                    bid_count: data.bpop_bidcount,
                                    bidtopstatus: 'outbid',
                                    bid_or_not: 1,
                                    date_closed,
                                    rank,
                                }
                                setViewProduct(product)
                            }
                        } else {
                            if (data.bpop_watchListAdded) {
                                productToChange.watchlistid = 1
                            }
                            product = {
                                ...productToChange,
                                next_bid: data.bpop_nextbid_org,
                                wprice: data.bpop_wprice,
                                bid_count: data.bpop_bidcount,
                                incrementamt: data.bpop_increment,
                                cbidtext: 'Current Price',
                                date_closed,
                            }
                            setViewProduct(product)
                        }
                    }
                }
                if (bidHistoryChange) {
                    bidHistoryChange({ product_id: productToChange.id })
                }
                // getBidHistoryProduct({ product_id: project_id })
            }
        } else if (type === 'preBidAddtime') {
            if (data.status === 'failed' && user_id === parseInt(data.bpop_cbidder)) {
                setAlert(data.error, 'error')
            }
            if (data.status !== 'failed') {
                if (user_id === parseInt(data.bpop_cbidder)) {
                    setAlert(
                        customMessage?.prebidSuccess || 'Pre Bid placed successfully',
                        'success',
                    )
                    product = {
                        ...productToChange,
                        prebid_data: {
                            proposed_amount: data.cuser_enteramount,
                            proposal: data.bidding_type,
                        },
                    }
                    setViewProduct(product)
                }
            }
        } else if (type === 'watchlistAdded') {
            product = {
                ...productToChange,
                watchlistid: data.status === 'added' ? 1 : 0,
            }
            setViewProduct(product)
        } else if (type === 'viewcount') {
            product = {
                ...productToChange,
                product_view_counts: data.view_count,
            }
            setViewProduct(product)
        }
    }
}

export const messageHandlerAuction = (data, auction, user, setAlert, setViewAuction, type) => {
    let user_id = user.id
    if (data.bpop_auctionid) {
        const index = auction.findIndex((s) => s.id === parseInt(data.bpop_auctionid, 10))
        let auctionToChange = auction[index]
        if (index !== -1) {
            if (type === 'bidAddtime') {
                let date_closed = auction[index].date_closed
                if (parseInt(data.bpop_belowFiveAuction) === 1) {
                    date_closed = data.bpop_belowFiveIncrement
                }
                if (data.status !== 'failed') {
                    auction[index] = {
                        ...auctionToChange,
                        date_closed,
                    }
                    setViewAuction([...auction])
                }
            }
        }
    }
}

export const messageHandlerTable = (
    data,
    product,
    user,
    setAlert,
    setViewProduct,
    type,
    bidHistoryChange,
    customMessage,
) => {
    console.log('i am socket handle ', data, type)
    let user_id = user ? user.id : 0
    const index = product.findIndex((s) => s.id === parseInt(data.id, 10))

    let productToChange = product[index]
    if (index !== -1) {
        if (type === 'realclosedupdates') {
            if (data.usr !== '') {
                if (user_id === parseInt(data.bpop_cbidder)) {
                    product[index] = {
                        ...productToChange,
                        market_status: 'sold',
                        bidtopstatus: 'won',
                        buynowamount: data.buynowamount,
                        cbidtext: 'Sold Price',
                        buynowed: data.bpop_cbidder,
                        buynowpaid: 0,
                        common_invoice: data.common_invoice,
                    }
                    setViewProduct([...product])
                } else if (parseInt(productToChange.bid_or_not) > 0) {
                    product[index] = {
                        ...productToChange,
                        market_status: 'sold',
                        bidtopstatus: 'lost',
                        buynowamount: data.buynowamount,
                        cbidtext: 'Sold Price',
                        buynowed: data.bpop_cbidder,
                        buynowpaid: 0,
                    }
                    setViewProduct([...product])
                } else {
                    product[index] = {
                        ...productToChange,
                        market_status: 'sold',
                        buynowamount: data.buynowamount,
                        cbidtext: 'Sold Price',
                    }
                    setViewProduct([...product])
                }
            } else {
                if (parseInt(productToChange.bid_or_not) > 0) {
                    product[index] = {
                        ...productToChange,
                        market_status: 'closed',
                        bidtopstatus: 'lost',
                    }
                    setViewProduct([...product])
                } else {
                    product[index] = {
                        ...productToChange,
                        market_status: 'closed',
                    }
                    setViewProduct([...product])
                }
            }
        } else if (type === 'customBidData') {
            if (data.status !== 'failed') {
                if (user_id === parseInt(data.bpop_cbidder)) {
                    product[index] = {
                        ...productToChange,
                        custom_bid_data: data?.customBidData?.length
                            ? [{ ...product[index]?.custom_bid_data[0], ...data?.customBidData[0] }]
                            : product[index]?.custom_bid_data,
                    }
                    console.log('i am setProduct', [...product])
                    setViewProduct([...product])
                }
            }
        } else if (type === 'bidAddtime') {
            let date_closed = product[index].date_closed
            if (parseInt(data.bpop_belowFive) === 1) {
                date_closed = data.bpop_belowFiveIncrement
            }
            if (data.status === 'failed' && user_id === parseInt(data.bpop_cbidder)) {
                setAlert(data.error, 'error')
            }
            if (data.status !== 'failed') {
                if (data.bidding_type === 'sealed') {
                    if (user_id === parseInt(data.bpop_cbidder)) {
                        setAlert(
                            customMessage?.sealedSuccess || 'Bid placed successfully',
                            'success',
                        )

                        product[index] = {
                            ...productToChange,
                            next_bid: data.bpop_cuser_nextbid,
                            wprice: data.bpop_wprice,
                            bidtopstatus: data.bpop_bidstatus,
                            bid_count: data.bpop_bidcount,
                            bid_or_not: 1,
                            date_closed,
                            cbidtext: 'Current Price',
                            current_bid: data.cuser_enteramount,
                        }
                        setViewProduct([...product])
                    } else {
                        if (parseInt(productToChange.bid_or_not) > 0) {
                            if (user_id === parseInt(data.bpop_higher)) {
                                if (
                                    parseInt(productToChange.next_bid) < parseInt(data.bpop_nextbid)
                                ) {
                                    product[index] = {
                                        ...productToChange,
                                        wprice: data.bpop_wprice,
                                        bidtopstatus: 'winner',
                                        bid_count: data.bpop_bidcount,
                                        date_closed,
                                    }
                                    setViewProduct([...product])
                                } else {
                                    product[index] = {
                                        ...productToChange,
                                        wprice: data.bpop_wprice,
                                        bidtopstatus: 'winner',
                                        bid_count: data.bpop_bidcount,
                                        date_closed,
                                    }
                                    setViewProduct([...product])
                                }
                            } else {
                                product[index] = {
                                    ...productToChange,
                                    wprice: data.bpop_wprice,
                                    bid_count: data.bpop_bidcount,
                                    bidtopstatus: 'outbid',
                                    bid_or_not: 1,
                                    date_closed,
                                }
                                setViewProduct([...product])
                            }
                        } else {
                            product[index] = {
                                ...productToChange,
                                wprice: data.bpop_wprice,
                                bid_count: data.bpop_bidcount,
                                date_closed,
                            }
                            setViewProduct([...product])
                        }
                    }
                } else {
                    if (user_id === parseInt(data.bpop_cbidder)) {
                        let rank = 0
                        if (data.auction_type && data.auction_type === 'reverse') {
                            if (data.bidhistory) {
                                const filteredData = [
                                    ...new Set(
                                        data.bidhistory.map((bidAll) => bidAll.user_id.toString()),
                                    ),
                                ]
                                const rankDetails = filteredData.indexOf(user_id.toString())

                                if (rank !== -1) {
                                    rank = rankDetails + 1
                                }
                            }
                        }

                        setAlert(customMessage?.bidSuccess || 'Bid placed successfully', 'success')
                        if (typeof product[index]?.proxy_bid_details === 'undefined') {
                            product[index].proxy_bid_details = {}
                        }
                        if (data.bpop_watchListAdded) {
                            productToChange.watchlistid = 1
                        }
                        product[index] = {
                            ...productToChange,
                            next_bid: data.bpop_cuser_nextbid,
                            wprice: data.bpop_wprice,
                            bidtopstatus: data.bpop_bidstatus,
                            bid_count: data.bpop_bidcount,
                            biddercurrentbid: data.cuser_enteramount,
                            incrementamt: data.bpop_increment,
                            bidderproxyant: data.bpop_bidderproxyant,
                            bid_or_not: 1,
                            date_closed,
                            proxy_bid_details: {
                                ...product[index]?.proxy_bid_details,
                                maxamount:
                                    data.bpop_bidstatus === 'winner'
                                        ? data.bpop_wprice_morehigh
                                        : product[index]?.proxy_bid_details.maxamount,
                                user_id:
                                    data.bpop_bidstatus === 'winner'
                                        ? data.bpop_cbidder
                                        : product[index]?.proxy_bid_details.bpop_cbidder,
                            },
                            cbidtext: 'Current Price',
                            rank,
                            userLocationAdded: true,
                            custom_bid_data: data?.customBidData?.length
                                ? [...product[index]?.custom_bid_data, data?.customBidData[0] || {}]
                                : product[index]?.custom_bid_data,
                        }
                        console.log('i am setProduct', [...product])
                        setViewProduct([...product])
                    } else {
                        if (parseInt(productToChange.bid_or_not) > 0) {
                            let rank = 0
                            if (data.auction_type && data.auction_type === 'reverse') {
                                if (data.bidhistory) {
                                    const filteredData = [
                                        ...new Set(
                                            data.bidhistory.map((bidAll) =>
                                                bidAll.user_id.toString(),
                                            ),
                                        ),
                                    ]
                                    const rankDetails = filteredData.indexOf(user_id.toString())

                                    if (rank !== -1) {
                                        rank = rankDetails + 1
                                    }
                                }
                            }
                            if (data.bpop_watchListAdded) {
                                productToChange.watchlistid = 1
                            }

                            if (user_id === parseInt(data.bpop_higher)) {
                                if (data.auction_type && data.auction_type === 'reverse') {
                                    if (
                                        parseInt(productToChange.next_bid) >
                                        parseInt(data.bpop_nextbid)
                                    ) {
                                        product[index] = {
                                            ...productToChange,
                                            next_bid: data.bpop_nextbid_org,
                                            wprice: data.bpop_wprice,
                                            bidtopstatus: 'winner',
                                            bid_count: data.bpop_bidcount,
                                            date_closed,
                                            rank,
                                        }
                                        setViewProduct([...product])
                                    } else {
                                        product[index] = {
                                            ...productToChange,
                                            wprice: data.bpop_wprice,
                                            bidtopstatus: 'winner',
                                            bid_count: data.bpop_bidcount,
                                            date_closed,
                                            rank,
                                        }
                                        setViewProduct([...product])
                                    }
                                } else {
                                    if (
                                        parseInt(productToChange.next_bid) <
                                        parseInt(data.bpop_nextbid)
                                    ) {
                                        product[index] = {
                                            ...productToChange,
                                            next_bid: data.bpop_nextbid_org,
                                            incrementamt: data.bpop_increment,
                                            wprice: data.bpop_wprice,
                                            bidtopstatus: 'winner',
                                            bid_count: data.bpop_bidcount,
                                            date_closed,
                                        }
                                        setViewProduct([...product])
                                    } else {
                                        product[index] = {
                                            ...productToChange,
                                            wprice: data.bpop_wprice,
                                            incrementamt: data.bpop_increment,
                                            bidtopstatus: 'winner',
                                            bid_count: data.bpop_bidcount,
                                            date_closed,
                                        }
                                        setViewProduct([...product])
                                    }
                                }
                            } else {
                                product[index] = {
                                    ...productToChange,
                                    next_bid: data.bpop_nextbid_org,
                                    wprice: data.bpop_wprice,
                                    incrementamt: data.bpop_increment,
                                    bid_count: data.bpop_bidcount,
                                    bidtopstatus: 'outbid',
                                    bid_or_not: 1,
                                    date_closed,
                                    rank,
                                }
                                setViewProduct([...product])
                            }
                        } else {
                            if (data.bpop_watchListAdded) {
                                productToChange.watchlistid = 1
                            }
                            product[index] = {
                                ...productToChange,
                                next_bid: data.bpop_nextbid_org,
                                wprice: data.bpop_wprice,
                                bid_count: data.bpop_bidcount,
                                incrementamt: data.bpop_increment,
                                cbidtext: 'Current Price',
                                date_closed,
                            }
                            setViewProduct([...product])
                        }
                    }
                }
                if (bidHistoryChange) {
                    bidHistoryChange({ product_id: productToChange.id })
                }
                // getBidHistoryProduct({ product_id: project_id })
            }
        } else if (type === 'preBidAddtime') {
            if (data.status === 'failed' && user_id === parseInt(data.bpop_cbidder)) {
                setAlert(data.error, 'error')
            }
            if (data.status !== 'failed') {
                if (user_id === parseInt(data.bpop_cbidder)) {
                    setAlert(
                        customMessage?.prebidSuccess || 'Pre Bid placed successfully',
                        'success',
                    )
                    product[index] = {
                        ...productToChange,
                        prebid_data: {
                            proposed_amount: data.cuser_enteramount,
                            proposal: data.bidding_type,
                        },
                    }
                    setViewProduct([...product])
                }
            }
        } else if (type === 'watchlistAdded') {
            product[index] = {
                ...productToChange,
                watchlistid: data.status === 'added' ? 1 : 0,
            }
            setViewProduct([...product])
        } else if (type === 'viewcount') {
            product[index] = {
                ...productToChange,
                product_view_counts: data.view_count,
            }
            setViewProduct([...product])
        }
    }
}
